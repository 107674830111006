import { createManualDisputeAPI } from '@library/api'
import { getMerchantDetails } from '@store/user/selectors'
import { Button, Drawer, Form, InboxOutlined, Input, message, TextArea, Upload } from 'gokwik-ui-kit'
import { useState } from 'react'
import { useSelector } from 'react-redux'
const { Dragger } = Upload

export const CreateManualDispute = (props) => {
  const [createManualDisputeForm] = Form.useForm()
  const { drawerOpen, closeDrawer, fetchShipmentData } = props
  const merchantConfigs = useSelector(getMerchantDetails)

  const [attachmentsList, setAttachmentList] = useState([])

  const handleCreateDispute = async () => {
    createManualDisputeForm
      .validateFields()
      .then(async (values) => {
        const formData = new FormData()
        formData.append('awb', drawerOpen)
        formData.append('merchant_id', merchantConfigs.m_id)

        Object.keys(values).forEach((key) => {
          formData.append(key, values[key])
        })

        attachmentsList.forEach((file) => {
          formData.append('file', file)
        })
        let response = await createManualDisputeAPI(formData)
        response = await response.json()
        if (response.status === 200) {
          closeDrawer('')
          message.success('Dispute Created Succesfully')
          fetchShipmentData()
        } else {
          message.error('Failed to create dispute. Please try again.')
        }
      })
      .catch((errorInfo) => {
        message.error('Please fill in all required fields')
      })
  }
  return (
    <Drawer
      title='Create Dispute'
      open={drawerOpen}
      onClose={() => {
        closeDrawer('')
      }}
    >
      <Form layout='vertical' name='create-manual-dispute' form={createManualDisputeForm}>
        <Form.Item
          label='Email Subject'
          name='email_subject'
          rules={[{ required: true, message: 'Please enter Email Subject!' }]}
        >
          <Input className='w-100' />
        </Form.Item>
        <Form.Item
          label='Email Body'
          name='email_body'
          rules={[{ required: true, message: 'Please enter Email Body!' }]}
        >
          <TextArea className='w-100' />
        </Form.Item>
        <Form.Item>
          <p style={{ marginBottom: '5px' }}>Email attachments (optional)</p>
          <Dragger
            fileList={attachmentsList}
            accept='all'
            name='file'
            maxCount={5}
            multiple={true}
            beforeUpload={(file, fileList) => {
              setAttachmentList(fileList)
              return false
            }}
            onRemove={() => {
              setAttachmentList([])
            }}
          >
            <p className='ant-upload-drag-icon'>
              <InboxOutlined />
            </p>
            <p className='ant-upload-text'>Click or drag file to this area to upload</p>
            <p className='text-[#00000073] text-sm'>*Maximum 5 attachments are allowed</p>
          </Dragger>
        </Form.Item>
        <Button style={{ width: '100%', height: '45px' }} variant='primary' onClick={() => handleCreateDispute()}>
          Create Dispute
        </Button>
      </Form>
    </Drawer>
  )
}

import ActionCenter from '@pages/ActionCenter'
import ShipmentAnalytics from '@pages/Analytics'
import BulkOperations from '@pages/BulkOperations'
import Orders from '@pages/Orders'
import Reports from '@pages/Reports'
import Shipments from '@pages/Shipments'
import ShipmentDetails from '@pages/Shipments/dynamic/shipmentDetails'
import { getMerchantDetails } from '@store/user/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'

const AppRoutes = () => {
  const merchantDetails = useSelector(getMerchantDetails)
  if (!Object.keys(merchantDetails || {}).length) return <></>
  return (
    <Routes key={`${JSON.stringify(merchantDetails)}`}>
      <Route path='/kwikship/shipments' Component={Shipments} />
      <Route path='/kwikship/orders' Component={Orders} />
      <Route path='/kwikship/bulk-operations' Component={BulkOperations} />
      <Route path='/kwikship/shipments/:orderCode' Component={ShipmentDetails} />
      <Route path='/kwikship/analytics' Component={ShipmentAnalytics} />
      <Route path='kwikship/need-action' Component={ActionCenter} />
      <Route path='kwikship/reports' Component={Reports} />
      <Route
        path='*'
        element={
          <div className='m-auto'>
            <p className='text-center'>404 Not found</p>
          </div>
        }
      />
    </Routes>
  )
}

export default AppRoutes

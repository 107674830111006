import React, { useState, useEffect } from 'react'
import {
  Table,
  DatePicker,
  Input,
  Button,
  FilterFilled,
  Select,
  Modal,
  message,
  Form,
  Popover,
  Menu,
  Dropdown,
  MoreOutlined,
  CheckOutlined,
  Tooltip,
  InfoCircleOutlined,
  Divider,
  Row,
  Col,
} from 'gokwik-ui-kit'
import { filterDateFormatter, updateBreadcrumbs } from '@gokwik/utilities'
import {
  cancelShipment,
  downloadShipmentLabel,
  getDashboardTableData,
  resolveManualDisputeAPI,
  shipmentReattempt,
} from '@library/api'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { getMerchantDetails } from '@store/user/selectors'
import { SearchOutlined } from 'gokwik-ui-kit'
import { RedoOutlined } from 'gokwik-ui-kit'
import { Link, useLocation } from 'react-router-dom'
import ShipmentsDownload from './components/ShipmentsDownload'
import { InitialValues } from './types/types'
import { ActionCenterStatus, DisputeStatus, PageTitles } from '@library/utilities/constants/shipments.constants'
import { CreateManualDispute } from '@library/components/create-dispute'

const { RangePicker } = DatePicker

const Shipments = () => {
  const today = dayjs()
  const [isLoading, setIsLoading] = useState(false)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [orders, setOrders] = useState<any>([])
  const merchantConfigs = useSelector(getMerchantDetails)
  const [popup, setPopup] = useState(false)
  const [selectedOrders, setSelectedOrders] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [editingRecord, setEditingRecord] = useState(null)
  const [form] = Form.useForm()

  const [filters, setFilters] = useState<any>({
    phone: '',
    awb: '',
    dateRange: [today.subtract(1, 'day'), today],
    status: '',
    payment_mode: '',
    reason: '',
    shipment_id: '',
    orderId: '',
    shipperId: '',
    limit: 20,
    page: 1,
  })
  const [awbForDispute, setAwbForDispute] = useState('')
  const [paramStatus, setParamStatus] = useState('')

  useEffect(() => {
    resetFilters()
    const searchParams = new URLSearchParams(location.search)
    const searchParamsObject = Object.fromEntries(searchParams.entries())
    if (Object.values(ActionCenterStatus).includes(searchParamsObject.status)) {
      const startDate = searchParamsObject.start_date ? dayjs(searchParamsObject.start_date) : today.subtract(1, 'day')

      const endDate = searchParamsObject.end_date ? dayjs(searchParamsObject.end_date) : today

      setFilters((prevFilters) => ({
        ...prevFilters,
        dateRange: [startDate, endDate],
        status: searchParamsObject.status,
        page: searchParamsObject.page ? parseInt(searchParamsObject.page) : prevFilters.page,
        limit: searchParamsObject.limit ? parseInt(searchParamsObject.limit) : prevFilters.limit,
      }))
    }
  }, [location.search])
  const statusOptions = [
    { value: 'new', label: 'New' },
    { value: 'to_be_picked', label: 'To be Picked' },
    { value: 'pickup_ready', label: 'Pickup Ready' },
    // { value: 'pickup_completed', label: 'Pickup Completed' },
    { value: 'in_transit', label: 'In Transit' },
    // { value: 'out_for_delivery', label: 'Out For Delivery' },
    { value: 'delivered', label: 'Delivered' },
    { value: 'rto', label: 'RTO' },
    ...(filters.status === ActionCenterStatus.NDR
      ? [{ value: ActionCenterStatus.NDR, label: 'Undelivered' }]
      : filters.status === ActionCenterStatus.DELAYED_PICKUPS
      ? [{ value: ActionCenterStatus.DELAYED_PICKUPS, label: 'Delayed Pickup' }]
      : []),
    // { value: 'rto_initiated', label: 'RTO Initiated' },
    // { value: 'cancelled', label: 'Cancelled' },
  ]

  const payment_modeOptions = [
    { value: 'COD', label: 'Cash on Delivery' },
    { value: 'PREPAID', label: 'Prepaid' },
  ]

  // adding shipperId options
  const shipperIdOptions = [
    { value: 'Shadowfax', label: 'Shadowfax' },
    { value: 'ShadowfaxNDD', label: 'ShadowfaxNDD' },
    { value: 'ShadowfaxSDD', label: 'ShadowfaxSDD' },
    { value: 'BlitzSDD', label: 'BlitzSDD' },
    { value: 'BlitzNDD', label: 'BlitzNDD' },
    { value: 'PicoNDD', label: 'PicoNDD' },
    { value: 'PicoSDD', label: 'PicoSDD' },
    { value: 'Delhivery', label: 'Delhivery' },
  ]

  // adding reason options
  const reason_Options = [
    { value: 'unable_to_contact_customer', label: 'Unable to contact customer' },
    { value: 'incorrect_address', label: 'Incorrect address' },
    { value: 'new_delivery_date', label: 'New delivery date' },
    { value: 'others', label: 'Others' },
    { value: 'shipment_not_attempted', label: 'Shipment not attempted' },
  ]

  const initialFilters = {
    phone: '',
    dateRange: [today.subtract(1, 'day'), today],
    awb: '',
    status: '',
    reason: '',
    payment_mode: '',
    shipment_id: '',
    orderId: '',
    shipperId: '',
    page: 1,
    limit: 20,
  }

  const filterArray = [
    {
      key: 'dateRange',
      type: 'date',
      onChange: (dates) => {
        setFilters({ ...filters, dateRange: dates })
      },
    },

    {
      key: 'awb',
      type: 'string',
      placeholder: 'AWB Number',
      onChange: (value) => {
        setFilters({ ...filters, awb: value })
      },
    },
    {
      key: 'status',
      type: 'select',
      placeholder: 'Status',
      options: statusOptions,
      hidden: filters.status === 'stuck_shipments' || filters.status === 'undelivered',
      onChange: (value) => {
        setFilters({ ...filters, status: value })
      },
    },
    {
      key: 'payment_mode',
      type: 'select',
      placeholder: 'Payment Mode',
      options: payment_modeOptions,
      onChange: (value) => {
        setFilters({ ...filters, payment_mode: value })
      },
    },
    {
      key: 'shipper_name',
      type: 'select',
      placeholder: 'Select Shipper',
      options: shipperIdOptions,
      onChange: (value) => {
        setFilters({ ...filters, shipper_name: value })
      },
    },
    {
      key: 'orderId',
      type: 'string',
      placeholder: 'Order Id',
      onChange: (value) => {
        setFilters({ ...filters, orderId: value })
      },
    },
  ].concat(
    // Conditionally add the reason filter
    filters.status === ActionCenterStatus.NDR
      ? [
          {
            key: 'reason',
            type: 'select',
            placeholder: 'NDR Reasons',
            options: reason_Options,
            onChange: (value) => {
              setFilters({ ...filters, reason: value })
            },
          },
        ]
      : [],
  )

  useEffect(() => {
    updateBreadcrumbs((prev) => [
      prev[0],
      {
        key: 'kwikship shipments',
        href: '/kwikship/shipments',
        text: getPageTitle(),
      },
    ])
    fetchShipmentData(1, 20)
  }, [filters])

  const getPageTitle = () => {
    if (status) {
      return PageTitles[status]
    }

    return 'Kwik Ship Shipments'
  }

  const handleReattemptSubmit = (record) => {
    // Constructing the payload
    const payload = {
      tracking_number: record.shipment_awb || null,
      shipper_id: record.shipment_shipper_id || null,
      action: 're-attempt',
      deferred_date: dayjs().add(1, 'day').format('YYYY-MM-DD'),
      action_by: 2,
    }

    shipmentReattempt(payload).then((response) => {
      if (response.status === 200) {
        message.success(response.message)
      } else {
        message.error(response.message)
      }
    })
  }

  const handleReattemptCancel = (record) => {
    try {
      const payload = {
        tracking_number: record.shipment_awb || null,
        shipper_id: record.shipment_shipper_id || null,
        action: 'return',
        deferred_date: '',
        phone: record.customer_phone || null,
        address: {
          address1: record.shipping_address || null,
          address2: record.shipment_pickup_details?.address2 || null,
          pincode: record.shipping_postalcode || null,
          city: record.shipping_country || null,
          state: record.shipping_state || null,
        },
        action_by: 3,
      }
      shipmentReattempt(payload).then((response) => {
        if (response.status === 200) {
          message.success(response.message)
        } else {
          message.error(response.message)
        }
      })
    } catch (error) {
      message.error('Something went wrong')
    }
  }

  const handleEditShipment = (updatedRecord) => {
    console.log(updatedRecord, 'here we are the edition record ')

    const payload = {
      tracking_number: updatedRecord.shipment_awb || null,
      shipper_id: updatedRecord.shipment_shipper_id || null,
      action: 're-attempt',
      deferred_date: updatedRecord.date ? dayjs(updatedRecord.date).format('YYYY-MM-DD') : null,
      phone: updatedRecord.phoneNo || null,
      address: {
        address1: updatedRecord.address || null,
        address2: updatedRecord.shipment_pickup_details?.address2 || null,
        pincode: updatedRecord.pincode || null,
        city: updatedRecord.shipping_country || null,
        state: updatedRecord.shipping_state || null,
      },
      action_by: 1,
    }
    console.log(payload, 'updated edit payload')
    shipmentReattempt(payload).then((response) => {
      if (response.status === 200) {
        message.success(response.message)
      } else {
        message.error(response.message)
      }
    })
  }

  const handleEditModal = () => {
    form
      .validateFields()
      .then((values) => {
        console.log(values, 'get values from the validateFields')
        console.log(editingRecord, 'editingRecord')
        const updatedRecord = {
          ...editingRecord,
          ...values,
        }
        if (values.address === editingRecord.shipping_address) {
          updatedRecord.address = null
        }
        if (values.phoneNo === editingRecord.customer_phone) {
          updatedRecord.phoneNo = null
        }
        if (values.pincode === editingRecord.shipping_postalcode) {
          updatedRecord.pincode = null
        }
        handleEditShipment(updatedRecord)
        setIsModalVisible(false)
      })
      .catch((errorInfo) => {
        console.log('Validation Failed:', errorInfo)
        message.error('Please fill in all required fields')
      })
  }

  const handleCancelModal = () => {
    setIsModalVisible(false)
  }

  const handleCancelShipment = (record) => {
    try {
      const payload = {
        waybill: record.shipment_awb || null,
      }
      cancelShipment(payload).then((response) => {
        if (response.status === 200) {
          message.success(response.message)
        } else {
          message.error(response.message)
        }
      })
    } catch (error) {
      message.error('Something went wrong while cancelling the shipment')
    }
  }

  const handleResolveDispute = async (awb: string) => {
    const body = {
      merchant_id: merchantConfigs.m_id,
      awb,
    }
    const response = await resolveManualDisputeAPI(body)

    if (response.status === 200) {
      message.success(`Dispute for ${awb} resolved!`)
      fetchShipmentData()
    } else {
      message.error(`Dispute resolution for ${awb} failed`)
    }
  }
  const getActionMenu = (record) => {
    const updatesAllowed = record.updates_allowed ? JSON.parse(record.updates_allowed) : []

    return (
      <Menu>
        {record.shipment_status === ActionCenterStatus.NDR && (
          <Menu.Item
            key='reattempt'
            onClick={() => handleReattemptSubmit(record)}
            disabled={record.action_taken}
            icon={record.action_by === 2 ? <CheckOutlined style={{ fontSize: '12px', color: '#52c41a' }} /> : null}
          >
            Reattempt
          </Menu.Item>
        )}

        {record.shipment_status === ActionCenterStatus.NDR && updatesAllowed.length > 0 && (
          <Menu.Item
            key='edit'
            disabled={record.action_taken}
            icon={record.action_by === 1 ? <CheckOutlined style={{ fontSize: '12px', color: '#52c41a' }} /> : null}
            onClick={() => {
              setEditingRecord(record)
              setIsModalVisible(true)

              const initialValues: InitialValues = {
                date: '',
                address: '',
                phoneNo: '',
                pincode: '',
              }
              if (updatesAllowed.includes('date')) {
                initialValues.date = dayjs().add(1, 'day')
              }
              if (updatesAllowed.includes('address')) {
                initialValues.address = record.shipping_address || ''
              }
              if (updatesAllowed.includes('pincode')) {
                initialValues.pincode = record.shipping_postalcode || ''
              }
              if (updatesAllowed.includes('phone')) {
                initialValues.phoneNo = record.customer_phone || ''
              }

              form.setFieldsValue(initialValues)
            }}
          >
            Edit
          </Menu.Item>
        )}

        {record.shipment_status === ActionCenterStatus.NDR && (
          <Menu.Item
            key='rto'
            disabled={record.action_taken}
            icon={record.action_by === 3 ? <CheckOutlined style={{ fontSize: '12px', color: '#52c41a' }} /> : null}
            onClick={() => handleReattemptCancel(record)}
          >
            Mark as RTO
          </Menu.Item>
        )}

        {filters.status === ActionCenterStatus.MANUAL_DISPUTES ? (
          <Menu.Item onClick={() => handleResolveDispute(record.shipment_awb)}>Resolve Dispute</Menu.Item>
        ) : (
          <>
            <Menu.Item
              disabled={record.dispute_tone}
              key='cancel'
              onClick={() => setAwbForDispute(record.shipment_awb)}
            >
              {record.dispute_tone ? 'Dispute Raised' : 'Raise Dispute'}
            </Menu.Item>

            <Menu.Item key='cancel' onClick={() => handleCancelShipment(record)}>
              Cancel
            </Menu.Item>
          </>
        )}
      </Menu>
    )
  }

  const columns = [
    {
      title: 'Order Id',
      dataIndex: 'ordercode',
      key: 'orderId',
      render: (orderCode: string) => (
        <Link to={`/kwikship/shipments/${encodeURIComponent(orderCode)}`}>{orderCode}</Link>
      ),
    },
    {
      title: 'AWB Number',
      dataIndex: 'shipment_awb',
      key: 'awb',
    },
    {
      title: 'Status',
      dataIndex: 'shipment_status',
      key: 'status',
      render: (text: string) => capitalizeWords(text.replace(/_/g, ' ')),
    },
    [ActionCenterStatus.MANUAL_DISPUTES, ActionCenterStatus.STUCK_SHIPMENTS].includes(filters.status) && {
      title: 'Dispute Status',
      dataIndex: 'dispute_tone',
      key: 'status',
      width: 150,
      render: (text: string, record: any) => (
        <>
          <span style={{ marginRight: '5px' }}>
            {DisputeStatus[record.dispute_tone] || DisputeStatus.dispute_to_be_raised}
          </span>
          {record.dispute_history?.length && (
            <Tooltip
              title={
                <>
                  {' '}
                  <p style={{ padding: '10px 5px', borderBottom: '1px solid #fff' }}>Dispute Activity</p>
                  {record.dispute_history?.map((history, index) => (
                    <div
                      style={{ borderBottom: '1px solid #fff', fontSize: '12px', fontWeight: 'normal', padding: '5px' }}
                      key={index}
                    >
                      <p>
                        Dispute ID:{' '}
                        <span style={{ textTransform: 'capitalize', fontSize: '14px' }}>{history.dispute_id}</span>{' '}
                      </p>

                      <p>
                        Type: <span style={{ textTransform: 'capitalize', fontSize: '14px' }}>{history.type}</span>{' '}
                      </p>
                      <p>
                        Subject:{' '}
                        <span style={{ textTransform: 'capitalize', fontSize: '14px' }}>{history.data.subject}</span>{' '}
                      </p>
                    </div>
                  ))}
                </>
              }
            >
              <InfoCircleOutlined className='opacity-40' />
            </Tooltip>
          )}
        </>
      ),
    },
    filters.status === ActionCenterStatus.NDR && {
      title: 'Last Status',
      dataIndex: 'last_status_update',
      key: 'last_status_update',
      render: (text: string) => new Date(text).toLocaleString(),
    },
    { title: 'Weight (g)', dataIndex: 'shipment_weight', key: 'shipment_weight' },
    { title: 'Payment Mode', dataIndex: 'shipment_payment_mode', key: 'shipment_payment_mode' },
    filters.status === ActionCenterStatus.NDR && {
      title: 'Delivery Address',
      dataIndex: 'shipping_address',
      key: 'shipping_address',
      render: (text: string, record) => `${text || ''}${record.shipping_state ? `, ${record.shipping_state}` : ''}`,
    },
    {
      title: 'Creation Date',
      dataIndex: 'shipment_creation_datetime',
      key: 'shipment_creation_datetime',
      render: (text: string) => new Date(text).toLocaleString(),
    },
    {
      title: 'Shipper',
      dataIndex: ['shippername'],
      key: 'shippername',
    },
    {
      title: 'Total Amount',
      dataIndex: 'shipment_total_amount',
      key: 'shipment_total_amount',
      render: (text: string) => `₹${text}`,
    },
    {
      title: 'Pickup Location',
      dataIndex: 'shipment_pickup_details',
      key: 'address1',
      render: (pickupDetails) => {
        const { address1, city, state } = pickupDetails || {}
        return `${address1 || ''}${city ? `, ${city}${state ? `, ${state}` : ''}` : ''}`
      },
    },
    ...(filters.status === ActionCenterStatus.NDR
      ? [
          {
            title: 'Contact Number',
            dataIndex: 'customer_phone',
            key: 'customer_phone',
          },
          {
            title: 'Last Status',
            dataIndex: 'last_status_update',
            key: 'last_status_update',
            render: (text: string) => new Date(text).toLocaleString(),
          },
          {
            title: 'NDR Reasons',
            dataIndex: 'shipper_remark',
            key: 'shipper_remark',
            render: (text: string) => text || '',
          },
          {
            title: 'NDR Attempt',
            dataIndex: 'ndr_attempt',
            key: 'ndr_attempt',
            render: (text: string) => text || '',
          },
        ]
      : []),
    ...(orders?.some((i) => i.show_label)
      ? [
          {
            title: '',
            dataIndex: 'operation',
            fixed: 'right',
            render: (_: any, record: any) =>
              record.show_label ? (
                <Button
                  onClick={() => downloadLabel(record.shipment_shipment_id)}
                  style={{ padding: ' 0px 20px' }}
                  type='primary'
                  size='small'
                >
                  Download Label
                </Button>
              ) : (
                ''
              ),
          },
        ]
      : []),
    filters.status !== ActionCenterStatus.STUCK_SHIPMENTS && {
      title: '',
      dataIndex: 'operation',
      fixed: 'right',
      render: (_: any, record: any) => (
        <Dropdown overlay={getActionMenu(record)} trigger={['click']}>
          <MoreOutlined />
        </Dropdown>
      ),
    },
  ].filter(Boolean)

  const fetchDataInChunks = async (configs) => {
    const { start_date, end_date, merchant_id, totalCount } = configs

    const CHUNK_SIZE = 20
    const chunks = Math.ceil(totalCount / CHUNK_SIZE)
    let allShipments = []

    for (let i = 0; i < chunks; i++) {
      try {
        const reqPayload = {
          start_date,
          end_date,
          merchant_id,
          page: i + 1,
          limit: CHUNK_SIZE,
        }
        const response = await getDashboardTableData(reqPayload)
        if (response.data.shipments) {
          allShipments = [...allShipments, ...response.data.shipments]
        }
      } catch (error) {
        console.error(`Error fetching chunk ${i + 1}:`, error)
      }
    }

    return allShipments
  }

  const getTooltipText = (dispute_history: Array<any>) => {
    dispute_history.map((history) => {
      ;<div>
        <p>
          Dispute ID: <span style={{ textTransform: 'capitalize' }}>{history.dispute_id}</span>{' '}
        </p>

        <p>
          Type: <span style={{ textTransform: 'capitalize' }}>{history.type}</span>{' '}
        </p>
        <p>
          Subject: <span style={{ textTransform: 'capitalize' }}>{history.data.subject}</span>{' '}
        </p>
      </div>
    })
  }

  const fetchShipmentData = async (page = 0, limit = 0, isDownload = false) => {
    let body = {}
    Object.keys(filters).forEach((filter) => {
      if (filters[filter] && filter !== 'dateRange') {
        if (filter === 'orderId') {
          body[filter] = encodeURIComponent(filters[filter]) // encode the orderCode
        } else {
          body[filter] = filters[filter]
        }
      }
    })

    try {
      // for normal table pagination
      if (!isDownload) {
        const response = await getDashboardTableData({
          start_date: filterDateFormatter(filters.dateRange[0]),
          end_date: filterDateFormatter(filters.dateRange[1]),
          merchant_id: merchantConfigs.m_id,
          page: filters.page,
          limit: filters.limit,
          ...body,
        })

        // if (page && limit) {
        //   setTableSettings({ ...tableSettings, page: 1 })
        // }

        if (response.data.shipments) {
          setOrders([])
          setOrders(response.data.shipments)
        } else {
          setOrders([])
        }

        if (response.data.total_count) {
          setTotalCount(response.data.total_count)
        } else {
          setTotalCount(0)
        }

        return response.data.shipments
      }

      // for download all data inexcel
      else {
        const initialResponse = await getDashboardTableData({
          start_date: filterDateFormatter(filters.dateRange[0]),
          end_date: filterDateFormatter(filters.dateRange[1]),
          merchant_id: merchantConfigs.m_id,
          page: 1,
          limit: 1,
          ...body,
        })

        if (!initialResponse.data.total_count) {
          return []
        }

        const allShipments = await fetchDataInChunks({
          start_date: filterDateFormatter(filters.dateRange[0]),
          end_date: filterDateFormatter(filters.dateRange[1]),
          merchant_id: merchantConfigs.m_id,
          totalCount: initialResponse.data.total_count,
          filters: body,
        })
        return allShipments
      }
    } catch (error) {
      console.error('Error fetching shipment data:', error)
      return []
    }
  }

  // Function to fetch all data for download
  const fetchAllDataForDownload = async () => {
    if (dayjs(filters.dateRange[1]).diff(dayjs(filters.dateRange[0])) / (24 * 60 * 60 * 1000) >= 8) {
      setPopup(true)
      return null
    }
    return await fetchShipmentData(0, 0, true)
  }

  const resetFilters = () => {
    setFilters(initialFilters)
  }

  const handlePaginationChange = (page) => {
    // setTableSettings({ ...tableSettings, page: newPage })
    setFilters({ ...filters, page })
    // fetchShipmentData()
  }

  function capitalizeWords(str) {
    return str
      .toLowerCase() // Convert the entire string to lowercase first
      .split(' ') // Split the string by spaces into an array of words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
      .join(' ') // Join the words back into a single string
  }

  const downloadLabel = async (specificShipment = null) => {
    let body: any = {}
    body.shipment_id = specificShipment
      ? [specificShipment]
      : orders.filter((i) => selectedOrders.includes(i.ordercode) && i.show_label).map((i) => i.shipment_shipment_id)
    body.merchant_id = merchantConfigs.m_id
    const response: any = await downloadShipmentLabel(body)

    if (response.status === 200 && response.data?.url) {
      // window.open(response.data.url, '_blank')

      const link = document.createElement('a')
      link.href = response.data.url // Set the file URL
      link.download = '' // Optionally, set a default file name
      document.body.appendChild(link)
      link.click() // Trigger the download
      document.body.removeChild(link)

      message.success('Generating label(s), please wait...')
    } else {
      message.error('Oops! Something went wrong')
    }
  }

  return (
    <main style={{ width: '100%' }}>
      <Modal
        closable={true}
        open={popup}
        cancelButtonProps={{ hidden: true }}
        onCancel={() => setPopup(false)}
        onOk={() => setPopup(false)}
      >
        Download is only allowed for maximum 7 days, please contact CSM for more info.
      </Modal>

      <CreateManualDispute
        drawerOpen={awbForDispute}
        fetchShipmentData={() => fetchShipmentData()}
        closeDrawer={setAwbForDispute}
      />

      {/* Edit Modal */}
      <Modal title='Edit Shipment Details' visible={isModalVisible} onOk={handleEditModal} onCancel={handleCancelModal}>
        <Form form={form} layout='vertical' name='edit_shipment_form'>
          {editingRecord?.updates_allowed && JSON.parse(editingRecord.updates_allowed).includes('date') && (
            <Form.Item
              name='date'
              label='Date'
              rules={[
                {
                  required: true,
                  message: 'Please select a date!',
                },
              ]}
            >
              <DatePicker
                style={{ width: '100%' }}
                defaultValue={dayjs()}
                disabledDate={(current) => {
                  const today = dayjs().startOf('day')
                  return current && (current < today || current > today.add(3, 'day'))
                }}
                format='YYYY-MM-DD'
              />
            </Form.Item>
          )}
          <Row gutter={16} justify='space-between'>
            {editingRecord?.updates_allowed && JSON.parse(editingRecord.updates_allowed).includes('address') && (
              <Col span={16}>
                <Form.Item
                  name='address'
                  label='Address'
                  // rules={[{ required: true, message: 'Please input the address!' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            )}
            {editingRecord?.updates_allowed && JSON.parse(editingRecord.updates_allowed).includes('pincode') && (
              <Col span={8}>
                <Form.Item
                  name='pincode'
                  label='Pincode'
                  // rules={[{ required: true, message: 'Please input the pincode!' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            )}
          </Row>

          {editingRecord?.updates_allowed && JSON.parse(editingRecord.updates_allowed).includes('phone') && (
            <Form.Item
              name='phoneNo'
              label='Phone Number'
              // rules={[
              //   { required: true, message: 'Please input the phone number!' },
              //   {
              //     pattern: /^[0-9]{10}$/,
              //     message: 'Please enter a valid 10-digit phone number!',
              //   },
              // ]}
            >
              <Input />
            </Form.Item>
          )}
        </Form>
      </Modal>
      <div className='bg bg-white p-3 rounded-md shadow-md mb-4'>
        <div className='mb-4 flex justify-between gap-4'>
          {filterArray.map((filter, index) => {
            switch (filter.type) {
              case 'date':
                return (
                  <RangePicker key={index} value={filters[filter.key]} onChange={filter.onChange} className='w-64' />
                )
              case 'select':
                return (
                  <Select
                    key={index}
                    placeholder={filter.placeholder}
                    options={filter.options}
                    onChange={(value) => filter.onChange(value)}
                    value={filters[filter.key] || undefined} // Use the dynamic key here
                    allowClear
                    style={{ display: filter.hidden ? 'none' : 'inherit' }}
                    className='w-48'
                  />
                )
              default:
                return (
                  <Input
                    key={index}
                    placeholder={filter.placeholder}
                    onChange={(e) => filter.onChange(e.target.value)}
                    value={filters[filter.key]} // Dynamically handle the correct input field
                    className='w-48'
                    style={{ display: filter.hidden ? 'none' : 'inherit' }}
                  />
                )
            }
          })}
        </div>
        <div className='flex justify-end items-center'>
          <div className='flex gap-3'>
            <ShipmentsDownload ordersToCsv={fetchAllDataForDownload} />

            <Button onClick={() => fetchShipmentData()} style={{ backgroundColor: '#004B9D', color: 'white' }}>
              <SearchOutlined />
              Search
            </Button>
            <Button onClick={resetFilters} style={{ border: '2px solid #004B9D', color: '#004B9D' }}>
              Reset Filters
            </Button>
          </div>
        </div>
      </div>
      <div className='bg-white '>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
          {orders.some((i) => i.show_label) ? (
            <Button
              onClick={() => downloadLabel()}
              type='primary'
              size='small'
              disabled={!selectedOrders.length}
              style={{
                position: 'absolute',
                top: '7px',
                right: '10px',
                zIndex: '2',
              }}
            >
              Download Selected Labels
            </Button>
          ) : (
            ''
          )}
        </div>
        <Table
          // @ts-ignore
          columns={columns}
          dataSource={orders}
          rowSelection={{
            onChange: (selectedRowKeys: React.Key[], selectedRows) => {
              setSelectedOrders(selectedRowKeys)
            },
            getCheckboxProps: (record) => {
              return {
                disabled: record.show_label !== 1,
                name: record.ordercode,
              }
            },
            selectedRowKeys: selectedOrders,
            type: 'checkbox',
          }}
          rowKey='ordercode'
          pagination={{
            current: filters.page,
            pageSize: filters.limit,
            total: totalCount,
            showSizeChanger: false,
            onChange: handlePaginationChange,
            position: ['topLeft', 'bottomLeft'],
            showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
          }}
          scroll={{ x: 'max-content' }}
        />
      </div>
    </main>
  )
}

export default Shipments

import React from 'react'
import { Row, Col, Table, Tag, Tooltip, Spin, Alert, InfoCircleOutlined, Divider } from 'gokwik-ui-kit'
import { useNavigate, useParams } from 'react-router-dom'
import { getShipmentDetails } from '@library/api'
import { updateBreadcrumbs } from '@gokwik/utilities'
import { ShipmentDetailsTypes } from '../types/types'
import AddressCard from '../components/AddressCard'
import CustomerDetails from '../components/CustomerDetailsCard'
import { formatDate, formatCurrency, formatStatus, weightInKg } from '@library/utilities/helpers/helpers'

const TABLE_SCROLL = { x: 1500, y: 300 }

// Component-specific constants
const LINE_ITEM_COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '25%',
    render: (text: string) => (
      <Tooltip title={text}>
        <div className='truncate max-w-[300px]'>{text || '-'}</div>
      </Tooltip>
    ),
  },
  {
    title: 'SKU Code',
    dataIndex: 'skuCode',
    key: 'skuCode',
    width: '20%',
    render: (text: string) => (
      <Tooltip title={text}>
        <div className='truncate'>{text || '-'}</div>
      </Tooltip>
    ),
  },
  {
    title: 'Item Price',
    dataIndex: 'itemPrice',
    key: 'itemPrice',
    width: '15%',
    render: (price: number) => formatCurrency(price),
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    width: '15%',
    render: (quantity: number) => quantity?.toString() || '-',
  },
  {
    title: 'HSN Code',
    dataIndex: 'hsnCode',
    key: 'hsnCode',
    width: '15%',
    render: (text: string) => (
      <Tooltip title={text}>
        <div className='truncate'>{text || '-'}</div>
      </Tooltip>
    ),
  },
]

const TRACKING_COLUMNS = [
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '15%',
    render: (status: string) => (
      <span className='!px-2 !py-1 rounded-lg capitalize truncate max-w-[150px] inline-block'>
        {formatStatus(status)}
      </span>
    ),
  },
  {
    title: 'Shipper Status',
    dataIndex: 'shipper_status',
    key: 'shipper_status',
    width: '20%',
    render: (status: string) => (
      <Tooltip title={status}>
        <span className='truncate max-w-[200px] inline-block'>{status || '-'}</span>
      </Tooltip>
    ),
  },
  {
    title: 'Shipper Remark',
    dataIndex: 'shipper_remark',
    key: 'shipper_remark',
    width: '25%',
    render: (remark: string) => (
      <Tooltip title={remark}>
        <span className='truncate max-w-[200px] inline-block'>{remark || '-'}</span>
      </Tooltip>
    ),
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    width: '15%',
    render: (location: string) => (
      <Tooltip title={location}>
        <span className='truncate max-w-[200px] inline-block'>{formatStatus(location) || '-'}</span>
      </Tooltip>
    ),
  },
  {
    title: 'Date & Time',
    dataIndex: 'status_datetime',
    key: 'status_datetime',
    width: '15%',
    render: (timestamp: string) => <span className='whitespace-nowrap'>{formatDate(timestamp)}</span>,
  },
]

// Main component
const ShipmentDetails: React.FC = () => {
  const { orderCode } = useParams<{ orderCode: string }>()
  const navigate = useNavigate()
  const [shipmentData, setShipmentData] = React.useState<ShipmentDetailsTypes>(null)
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState<string | null>(null)

  const fetchShipmentDetails = React.useCallback(async () => {
    if (!orderCode) {
      setError('No Order Code provided')
      setLoading(false)
      return
    }

    try {
      setLoading(true)
      setError(null)
      const response = await getShipmentDetails(orderCode)

      // Safely parse JSON data with type checking
      const parseJSON = <T,>(data: string | T): T => {
        if (typeof data === 'string') {
          try {
            return JSON.parse(data)
          } catch (e) {
            throw new Error('Invalid JSON data')
          }
        }
        return data
      }

      const parsedData = parseJSON(response.data.shipmentDetails)
      const parsedStatusHistory = parseJSON(response.data.statusHistory)

      setShipmentData({
        ...parsedData,
        statusHistory: parsedStatusHistory,
      })
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to fetch shipment details'
      setError(errorMessage)
      console.error('Error fetching shipment details:', err)
    } finally {
      setLoading(false)
    }
  }, [orderCode])

  // Update breadcrumbs when orderCode changes
  React.useEffect(() => {
    updateBreadcrumbs((prev) => [
      prev[0],
      {
        key: 'kwikship-shipments',
        href: '/kwikship/shipments',
        text: 'Kwik Ship Shipments',
      },
      {
        key: 'shipment-details',
        href: `/kwikship/shipments/${orderCode}`,
        text: orderCode || 'Details',
      },
    ])
  }, [orderCode])

  // Fetch data on mount and when orderCode changes
  React.useEffect(() => {
    fetchShipmentDetails()
  }, [fetchShipmentDetails])

  if (loading) {
    return (
      <div className='w-full h-screen flex items-center justify-center'>
        <Spin />
      </div>
    )
  }

  if (error) {
    return (
      <div className='w-full h-screen flex flex-col items-center justify-center gap-4'>
        <Alert
          message='Error'
          description={error}
          type='error'
          showIcon
          action={
            <button
              onClick={fetchShipmentDetails}
              className='px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors'
            >
              Retry
            </button>
          }
        />
      </div>
    )
  }

  if (!shipmentData) {
    return (
      <div className='w-full h-screen flex items-center justify-center'>
        <Alert message='No data available' type='info' showIcon />
      </div>
    )
  }

  const currentStatus = shipmentData.statusHistory?.[shipmentData.statusHistory.length - 1]?.status
  const lineItems = shipmentData.product_details.items.map((item) => ({
    ...item,
    key: item.skuCode,
  }))

  const trackingData = shipmentData.statusHistory.map((item, index) => ({
    ...item,
    key: index,
  }))

  // creating a dyamic order Details array with title: and data:
  const keyInformation = [
    [
      {
        label: 'AWB',
        value: (
          <a
            href={`${process.env.TRACKING_URL}${shipmentData.awb}`}
            target='_blank'
            rel='noopener noreferrer'
            className='text-blue-600 underline hover:text-blue-700 transition-colors'
          >
            {shipmentData.awb}
          </a>
        ),
      },

      { label: 'Length (cm)', value: shipmentData.product_details.length },
      { label: 'Breadth (cm)', value: shipmentData.product_details.breadth },
      { label: 'Height (cm)', value: shipmentData.product_details.height },
      { label: 'Weight (kg)', value: weightInKg(shipmentData.product_details.weight) },
    ],
    [
      { label: 'Order Date', value: formatDate(shipmentData.creation_datetime) },
      // { label: 'Shopify Order ID', value: shipmentData.delivery_details.city },
      // { label: 'Coupon Code', value: shipmentData.delivery_details.state },
      { label: 'Order Status', value: formatStatus(currentStatus) || '-' },
      // { label: 'Platform Order Status', value: shipmentData.pickup_details.city },
    ],
    [
      { label: 'Payment Status', value: shipmentData.total_amount ? 'true' : 'false' },
      // { label: 'Store Source', value: shipmentData.delivery_details.phone },
      // { label: 'Events Log', value: shipmentData.delivery_details.phone },
      { label: 'Payment Amount', value: `₹ ${shipmentData.total_amount}` || '₹ 0' },
      // { label: 'Total Discount', value: shipmentData.delivery_details.phone },
      { label: 'Payment Method', value: shipmentData.payment_mode },
    ],
  ]

  return (
    <div className='order-details-page w-full flex flex-col gap-y-4 p-4'>
      <Row className='p-4 rounded bg-neutrals-15 shadow-sm' align='middle' justify='space-between'>
        <Col sm={12} className='flex items-center gap-x-2'>
          <span className='text-black text-xl font-semibold m-0 tracking-wide'>
            Order {shipmentData.product_details.orderCode}
          </span>
          <Tag
            color={currentStatus?.toLowerCase() === 'delivered' ? 'success' : 'processing'}
            className='!px-3 !py-1 rounded-2xl font-medium capitalize'
          >
            {formatStatus(currentStatus)}
          </Tag>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col className='flex flex-col gap-y-4' sm={18}>
          <Row className='p-2 rounded bg-neutrals-15' gutter={[0, 12]} align={'middle'} justify={'space-between'}>
            <Col sm={24}>
              <span className='text-black text-base inter font-semibold m-0'>Details</span>
            </Col>
            <Col sm={24}>
              <Row gutter={16} justify={'space-between'}>
                {keyInformation.map((itm, i) => (
                  <React.Fragment key={i}>
                    <Col className='flex flex-col gap-y-2' span={i === 0 ? 8 : 7}>
                      {itm.map((key) => (
                        <p style={{ padding: '.1rem' }} className='inter flex text-sm w-full' key={key.label}>
                          <span className='text-gray-600 whitespace-nowrap'>{key.label}:</span>
                          <span className='text-black font-medium flex-grow ml-2 truncate'>
                            <Tooltip title={key.value || ''}>{key.value || '-'}</Tooltip>
                          </span>
                        </p>
                      ))}
                    </Col>
                    {i !== keyInformation.length - 1 && <Col span={1} className='border-r h-full' />}
                  </React.Fragment>
                ))}
              </Row>
            </Col>
          </Row>
          {/* Order Items */}
          <Row className='p-4 rounded bg-neutrals-15 shadow-sm' gutter={[0, 12]}>
            <Col span={24}>
              <span className='text-black text-lg font-semibold'>Items</span>
            </Col>
            <Col span={24}>
              <Table
                columns={LINE_ITEM_COLUMNS}
                dataSource={lineItems}
                pagination={false}
                scroll={TABLE_SCROLL}
                className='shadow-sm'
              />
            </Col>
          </Row>

          {/* Tracking History */}
          <Row className='p-4 rounded bg-neutrals-15 shadow-sm' gutter={[0, 12]}>
            <Col span={24}>
              <span className='text-black text-lg font-semibold'>Tracking History</span>
            </Col>
            <Col span={24}>
              <Table
                columns={TRACKING_COLUMNS}
                dataSource={trackingData}
                pagination={false}
                scroll={TABLE_SCROLL}
                className='shadow-sm'
              />
            </Col>
          </Row>
        </Col>

        <Col className='flex flex-col gap-y-4' sm={6}>
          <CustomerDetails details={shipmentData.delivery_details} />
          <AddressCard title='Shipping Details' address={shipmentData.delivery_details} />
          <AddressCard title='Billing Details' address={shipmentData.delivery_details} />
        </Col>
      </Row>
    </div>
  )
}

export default ShipmentDetails

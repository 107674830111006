import { Button } from 'gokwik-ui-kit'
import { downloadToCSV } from '@gokwik/utilities'
import { useState } from 'react'

const ShipmentsDownload = ({ ordersToCsv }) => {
  const [isLoading, setIsLoading] = useState(false)
  // console.log(ordersToCsv)
  const handleDownload = async () => {
    const allOrders = await ordersToCsv()
    if (!allOrders) {
      return
    }
    setIsLoading(true)
    try {
      // Define the columns we want to include in the CSV
      const cellKeys = [
        'shipment_awb',
        'ordercode',
        'customer_name',
        'customer_phone',
        'customer_email',
        'shipment_status',
        'shipment_payment_mode',
        'shipment_weight',
        'shipment_total_amount',
        'shippername',
        'shipping_address',
        'shipping_state',
        'shipping_country',
        'shipping_postalcode',
        'pickup_location',
        'orderdate',
        'shipment_creation_datetime',
        'products',
      ]

      // Define user-friendly headers for each column
      const cellHeadings = [
        'AWB Number',
        'Order Code',
        'Customer Name',
        'Phone',
        'Email',
        'Status',
        'Payment Mode',
        'Weight (g)',
        'Total Amount',
        'Shipper',
        'Shipping Address',
        'State',
        'Country',
        'Postal Code',
        'Pickup Location',
        'Order Date',
        'Shipment Created At',
        'Products',
      ]

      // Process the data to format certain fields
      const processedData = allOrders.map((order) => ({
        ...order,
        shipment_status: order.shipment_status
          ?.replace(/_/g, ' ')
          .toLowerCase()
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' '),
        shipment_total_amount: order.shipment_total_amount?.toString(),
        shipment_creation_datetime: new Date(order.shipment_creation_datetime).toLocaleString(),
        shipping_address: order.shipping_address?.replace(/,/g, ' '), // Remove commas to prevent CSV formatting issues
        pickup_location: `${order.shipment_pickup_details?.address1}, ${order.shipment_pickup_details?.city}, ${order.shipment_pickup_details?.state}, ${order.shipment_pickup_details?.country}`,

        // Format the products column with multiline entries
        products: order.items
          ? `"${JSON.parse(order.items)
              .map((item) => `Name: ${item.name}\nSize: ${item.size}\n`)
              .join('\n\n')}"` // Double newline to separate each product entry
          : '',
      }))

      // Download the CSV with serial numbers
      downloadToCSV(cellKeys, cellHeadings, processedData, `shipments_${new Date().toISOString().split('T')[0]}`, {
        addSerialNumber: true,
      })
    } catch (error) {
      console.error('Error downloading data:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Button onClick={handleDownload} className='flex items-center gap-2' disabled={isLoading}>
      {isLoading ? 'Downloading...' : 'Download CSV'}
    </Button>
  )
}

export default ShipmentsDownload

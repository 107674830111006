// @ts-nocheck
import { AnalyticsCard, Chart, Col, DatePicker, Row, StatsCards, SummaryBlocks } from 'gokwik-ui-kit'
import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { filterDateFormatter, updateBreadcrumbs } from '@gokwik/utilities'
import { summary_blocks } from '@library/utilities/constants/analytics.constants'
import FunnelChart from '@library/components/bar-graph'
import { getShipmentAnalytics } from '@library/api'
import { useSelector } from 'react-redux'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import AnalyticsSummaryCard from './components/AnalyticsSummaryBlock'

const graphs = {
  forward: {
    title: 'Forward Logistics',
    keyName: 'total_orders',
    hero_value: 0,
    hero_title: 'Total orders',
    type: 'forward',
    hero_value_change: 0,
    tooltipText: 'Total Orders',
  },
  reverse: {
    title: 'Return Logistics',
    keyName: 'returned',
    hero_value: 0,
    type: 'reverse',
    hero_title: 'New Return Requests',
    hero_value_change: 0,
    tooltipText: 'Total Orders',
  },
  return: {
    title: 'Reverse Logistics',
    keyName: 'undelivered',
    hero_value: 0,
    type: 'return',
    hero_title: 'NDR Rate',
    hero_value_change: 0,
    tooltipText: 'Total Orders',
  },
}

const ShipmentAnalytics = () => {
  const merchantConfigs = useSelector(getMerchantDetails)
  const userDetails = useSelector(getUserDetails)
  const today = dayjs()
  const [timeframe, setTimeframe] = useState([today.subtract(1, 'day'), today])
  const [summaryData, setSummaryData] = useState(summary_blocks)
  const [graphData, setGraphData] = useState({ forward: {}, reverse: {}, return: {} })
  const [analyticsAPIData, setAnalyticsAPIDate] = useState(null)
  const [loading, setLoading] = useState(true)
  const handleDateChange = (start, end) => {
    setTimeframe([start, end])
    fetchAnalytics({
      start_date: filterDateFormatter(start),
      end_date: filterDateFormatter(end),
      merchant_id: merchantConfigs.m_id,
    })
  }
  const [selectedGraph, setSelectedGraph] = useState(graphs.forward)
  const processData = (response) => {
    let summaryData = summary_blocks
    summaryData = summaryData.map((block) => ({
      ...block,
      value: response.summary[block.key].current,
      comparison: response.summary[block.key].change * 100,
    }))
    return summaryData
  }

  const fetchAnalytics = async (params) => {
    setLoading(true)
    let response = await getShipmentAnalytics(params)
    response = response.data
    setAnalyticsAPIDate(response)
    setLoading(false)
    return response
  }

  useEffect(() => {
    if (analyticsAPIData) {
      setSummaryData(processData(analyticsAPIData))
      setGraphData(analyticsAPIData.graphData)
      handleGraphChange('forward')
    }
  }, [analyticsAPIData])

  const handleGraphChange = (graphName) => {
    setSelectedGraph({
      ...graphs[graphName],
      hero_value: analyticsAPIData.summary?.[graphs[graphName].keyName]?.current || 0,
      hero_value_change: analyticsAPIData.summary?.[graphs[graphName].keyName]?.change || 0,
      type: graphName,
    })
  }

  useEffect(() => {
    fetchAnalytics({
      start_date: filterDateFormatter(timeframe[0]),
      end_date: filterDateFormatter(timeframe[1]),
      merchant_id: merchantConfigs.m_id,
    })
    updateBreadcrumbs((prev) => [
      prev[0],
      {
        key: 'kwikship analytics',
        href: '/kwikship/analytics',
        text: 'Kwik Ship Analytics',
      },
    ])
  }, [merchantConfigs])
  return (
    <main>
      <Row align='stretch' className='pb-3'>
        <DatePicker.RangePicker
          value={timeframe}
          onChange={([start, end]) => {
            handleDateChange(start, end)
          }}
        />
      </Row>
      <Row gutter={[16, 16]} className='pb-3'>
        {summaryData.map((block, index) => (
          <AnalyticsSummaryCard key={index} block={block} diff_days={analyticsAPIData?.diff_days || 0} loading />
        ))}
      </Row>
      <div className='my-2 rounded-md py-2 px-1 graph-area'>
        <div className='flex gap-3 px-1 pb-2 font-bold graph-title'>
          {Object.keys(graphs).map((graph, index) => (
            <button
              onClick={() => handleGraphChange(graph)}
              key={index}
              className={`text-sm py-2 ${selectedGraph.type === graph ? 'selected-option' : ''}`}
            >
              {graphs[graph].title}
            </button>
          ))}
        </div>
        <AnalyticsCard
          title={selectedGraph.hero_title}
          heroValue={selectedGraph.hero_value}
          valueDelta={selectedGraph.hero_value_change * 100}
          titleTooltip={selectedGraph.tooltipText}
          subHeading='SHIPMENT DATA OVER TIME'
          loading={loading}
        >
          <FunnelChart
            {...{
              data: graphData[selectedGraph.type],
              type: selectedGraph.type,
              maxValue: graphData[selectedGraph.type][selectedGraph.keyName],
            }}
          />
        </AnalyticsCard>
      </div>
    </main>
  )
}

export default ShipmentAnalytics

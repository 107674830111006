import { Button, Col, DownloadOutlined, InboxOutlined, Row, Upload, message } from 'gokwik-ui-kit'
import Test from '@public/assets/discount_sample.csv'
import { useSelector } from 'react-redux'
import { getMerchantDetails } from '@store/user/selectors'
import { FileUpload } from '@library/components/file-upload'
import { bulkOrderUpdate } from '@library/api'

export const BulkUpdateOrders = () => {
  const merchantConfigs = useSelector(getMerchantDetails)

  const uploadCsv = async (file, fileList) => {
    const formData = new FormData()
    formData.append('file', file)
    // formData.append('fileName', file.name)

    let response = await bulkOrderUpdate({ merchant_id: merchantConfigs.m_id }, formData)
    response = await response.json()
    if (response.status === 200) {
      message.success('Bulk processing has started...')
    } else {
      message.error('Oops! something went wrong')
    }
  }

  return <FileUpload uploadCSV={uploadCsv} />
}

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserOutlined, ShoppingCartOutlined, CloseCircleOutlined } from 'gokwik-ui-kit'
import { getDelayAndNdrCount } from '@library/api'
import { getMerchantDetails } from '@store/user/selectors'
import dayjs from 'dayjs'
import './styles/NeedAction.css'
import { useSelector } from 'react-redux'
import { DelayAndNdrCountResponse } from './types/types'
import { ActionCenterStatus } from '@library/utilities/constants/shipments.constants'

const ActionCenter = () => {
  const [data, setData] = useState<DelayAndNdrCountResponse>({})
  const navigate = useNavigate()
  const merchantConfigs = useSelector(getMerchantDetails)

  // fetch m_id here

  // In your useEffect
  useEffect(() => {
    const getDelayAndNdrCountData = async () => {
      try {
        const queryParams = {
          start_date: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
          end_date: dayjs().format('YYYY-MM-DD'),
          merchant_id: merchantConfigs.m_id,
          page: 1,
          limit: 10,
          status: 'undelivered',
        }

        const data = await getDelayAndNdrCount(queryParams)

        setData(data)
      } catch (error) {
        console.error('Error fetching delay and NDR count data:', error)
      }
    }
    getDelayAndNdrCountData()
  }, [])

  const handleNdrClick = () => {
    // Construct the query parameters for NDR filter
    const today = dayjs()
    const queryParams = new URLSearchParams({
      start_date: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
      end_date: today.format('YYYY-MM-DD'),
      status: ActionCenterStatus.NDR,
    })

    // Navigate to shipments route with NDR filter parameters
    navigate(`/kwikship/shipments?${queryParams.toString()}`)
  }

  const handleManualDisputesClick = () => {
    const today = dayjs()
    const queryParams = new URLSearchParams({
      start_date: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
      end_date: today.format('YYYY-MM-DD'),
      status: ActionCenterStatus.MANUAL_DISPUTES,
    })

    navigate(`/kwikship/shipments?${queryParams.toString()}`)
  }

  const handleStuckShipmentsClick = () => {
    const today = dayjs()
    const queryParams = new URLSearchParams({
      start_date: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
      end_date: today.format('YYYY-MM-DD'),
      status: ActionCenterStatus.STUCK_SHIPMENTS,
    })

    navigate(`/kwikship/shipments?${queryParams.toString()}`)
  }

  const handleDelayClick = () => {
    // Construct the query parameters for delay filter
    const today = dayjs()
    const queryParams = new URLSearchParams({
      start_date: today.subtract(30, 'day').format('YYYY-MM-DD'),
      end_date: today.format('YYYY-MM-DD'),
      status: ActionCenterStatus.DELAYED_PICKUPS,
    })

    // Navigate to shipments route with delay filter parameters
    navigate(`/kwikship/shipments?${queryParams.toString()}`)
  }
  const stats = [
    {
      value: data.data?.delayed_pickup,
      label: 'Pickup Exemption',
      icon: <CloseCircleOutlined className='stats-icon' />,
      onClick: handleDelayClick,
    },
    {
      value: data.data?.ndr_shipment,
      label: 'NDR',
      icon: <ShoppingCartOutlined className='stats-icon' />,
      onClick: handleNdrClick,
    },
    {
      value: data.data?.stuck_shipments,
      label: 'Stuck Shipments',
      icon: <ShoppingCartOutlined className='stats-icon' />,
      onClick: handleStuckShipmentsClick,
    },
    {
      value: data.data?.manual_disputes,
      label: 'Manual Disputes',
      icon: <ShoppingCartOutlined className='stats-icon' />,
      onClick: handleManualDisputesClick,
    },
  ]

  return (
    <div className='stats-container'>
      {stats.map((stat, index) => (
        <div key={index} className='stats-card cursor-pointer' onClick={stat.onClick}>
          <div className='stats-content'>
            <div className='stats-info'>
              <h2 className='stats-value'>{stat.value}</h2>
              <p className='stats-label'>{stat.label}</p>
              <p className='stats-label underline'>Last 30 days </p>
            </div>
            <div className='icon-wrapper'>{stat.icon}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ActionCenter

import { Button } from 'gokwik-ui-kit'
import { downloadToCSV } from '@gokwik/utilities'
import { useState } from 'react'
import { cleanString } from '@library/utilities/helpers/helpers'

const OrdersDownload = ({ ordersToCsv }) => {
  const [isLoading, setIsLoading] = useState(false)
  const handleDownload = async () => {
    const allOrders = await ordersToCsv()
    if (!allOrders) {
      return
    }
    setIsLoading(true)
    try {
      // Define the columns we want to include in the CSV
      const cellKeys = [
        'order_id',
        'shipment_creation_datetime', // Corresponds to 'Order Date'
        'channel',
        'payment_method',
        'first_name',
        'last_name',
        'customer_phone', // From processedData
        'customer_email', // From processedData
        'shipping_address_1', // Corresponds to 'Shipping Address Line 1'
        'shipping_address_2', // Corresponds to 'Shipping Address Line 2'
        'shipping_address_country',
        'shipping_address_state',
        'shipping_address_city',
        'shipping_address_postcode',
        'billing_address_1', // Corresponds to 'Billing Address Line 1'
        'billing_address_2', // Corresponds to 'Billing Address Line 2'
        'billing_address_country',
        'billing_address_state',
        'billing_address_city',
        'billing_address_postcode',
        'product_name', // Refers to the multiline product details
        'product_sku',
        'product_quantity',
        'product_price', // Selling price per unit
        'product_hsn',
        'length',
        'breadth',
        'height',
        'weight', // Weight of Shipment (kg)
        'collectable_amount', // Amount to collect
      ]

      //   *Order Id	Order Date as dd-mm-yyyy hh:MM	*Channel	*Payment Method(COD/Prepaid)	*Customer First Name	Customer Last Name	Email (Optional)	*Customer Mobile	Customer Alternate Mobile	*Shipping Address Line 1	Shipping Address Line 2	*Shipping Address Country	*Shipping Address State	*Shipping Address City	*Shipping Address Postcode	Billing Address Line 1	Billing Address Line 2	Billing Address Country	Billing Address State	Billing Address City	Billing Address Postcode	*Master SKU	*Product Name	*Product Quantity	Tax %	*Selling Price(Per Unit Item, Inclusive of Tax)	Discount(Per Unit Item)	Shipping Charges(Per Order)	COD Charges(Per Order)	Gift Wrap Charges(Per Order)	Total Discount (Per Order)	*Length (cm)	*Breadth (cm)	*Height (cm)	*Weight Of Shipment(kg)	Send Notification(True/False)	Comment	HSN Code	Location Id	Reseller Name	Company Name	latitude	longitude	package_count	Verified Order	Is documents	Order Type	Order tag

      // Define user-friendly headers for each column
      const cellHeadings = [
        'Order Id',
        'Order Date',
        'Channel',
        'Payment Method(COD/Prepaid)',
        'Customer First Name',
        'Customer Last Name',
        'Customer Mobile',
        'Email',
        'Shipping Address Line 1',
        'Shipping Address Line 2',
        'Shipping Address Country',
        'Shipping Address State',
        'Shipping Address City',
        'Shipping Address Postcode',
        'Billing Address Line 1',
        'Billing Address Line 2',
        'Billing Address Country',
        'Billing Address State',
        'Billing Address City',
        'Billing Address Postcode',
        'Product Name',
        'Product SKU',
        'Product Quantity',
        'Selling Price',
        'HSN Code',
        'Length (cm)',
        'Breadth (cm)',
        'Height (cm)',
        'Weight Of Shipment(g)',
        'Collectable Amount',
      ]

      // Process the data to format certain fields
      const processedData = allOrders.map((order) => ({
        order_id: order.order_orderId,
        channel: 'CUSTOM',
        payment_method: order.order_paymentMode,
        collectable_amount: order.order_collectableAmount,
        shipment_creation_datetime: new Date(order.order_Shipment.orderDate).toLocaleString(),
        shipping_address_1: cleanString(order.order_deliveryAddressDetails?.address1),
        shipping_address_2: '',
        shipping_address_country: cleanString(order.order_deliveryAddressDetails?.country),
        shipping_address_state: order.order_deliveryAddressDetails?.state,
        shipping_address_city: order.order_deliveryAddressDetails?.city,
        shipping_address_postcode: order.order_deliveryAddressDetails?.pincode,

        billing_address_1: cleanString(order.order_deliveryAddressDetails?.address1),
        billing_address_2: '',
        billing_address_country: order.order_deliveryAddressDetails?.country,
        billing_address_state: order.order_deliveryAddressDetails?.state,
        billing_address_city: order.order_deliveryAddressDetails?.city,
        billing_address_postcode: order.order_deliveryAddressDetails?.pincode,

        // Format the products column with multiline entries
        product_name: `"${order.order_Shipment.items.map((item) => cleanString(item.name)).join('\n')}"`,
        product_quantity: `"${order.order_Shipment.items.map((item) => item.quantity).join('\n')}"`,
        product_sku: `"${order.order_Shipment.items.map((item) => item.skuCode).join('\n')}"`,
        product_price: `"${order.order_Shipment.items.map((item) => item.itemPrice).join('\n')}"`,
        product_hsn: `"${order.order_Shipment.items.map((item) => item.hsnCode).join('\n')}"`,

        length: order.order_Shipment.length,
        breadth: order.order_Shipment.breadth,
        height: order.order_Shipment.height,
        customer_phone: order.order_deliveryAddressDetails.phone,
        customer_email: order.order_deliveryAddressDetails.email,
        weight: order.order_Shipment.weight,
        first_name: order.order_deliveryAddressDetails.name.split(' ')[0],
        last_name: order.order_deliveryAddressDetails.name.split(' ')[1],
      }))
      // Download the CSV with serial numbers
      downloadToCSV(cellKeys, cellHeadings, processedData, `orders_${new Date().toISOString().split('T')[0]}`, {
        addSerialNumber: true,
      })
    } catch (error) {
      console.error('Error downloading data:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Button onClick={handleDownload} className='flex items-center gap-2' disabled={isLoading}>
      {isLoading ? 'Downloading...' : 'Download CSV'}
    </Button>
  )
}

export default OrdersDownload

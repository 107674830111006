import React, { useState, useEffect } from 'react'
import { Table, DatePicker, Input, Button, FilterFilled, Select, Modal, message } from 'gokwik-ui-kit'
import { filterDateFormatter, updateBreadcrumbs } from '@gokwik/utilities'
import { downloadShipmentLabel, getDashboardTableData } from '@library/api'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { getMerchantDetails } from '@store/user/selectors'
import { SearchOutlined } from 'gokwik-ui-kit'
import { RedoOutlined } from 'gokwik-ui-kit'
import { Link } from 'react-router-dom'
import ShipmentsDownload from './components/ShipmentsDownload'
import Label from 'gokwik-ui-kit/dist/esm/components/Label'

const { RangePicker } = DatePicker

const Shipments = () => {
  const today = dayjs()
  const [totalCount, setTotalCount] = useState<number>(0)
  const [orders, setOrders] = useState<any>([])
  const merchantConfigs = useSelector(getMerchantDetails)
  const [popup, setPopup] = useState(false)
  const [selectedOrders, setSelectedOrders] = useState([])
  const [tableSettings, setTableSettings] = useState<{ page: number; limit: number }>({
    limit: 20,
    page: 1,
  })
  const [filters, setFilters] = useState<any>({
    phone: '',
    awb: '',
    dateRange: [today.subtract(1, 'day'), today],
    status: '',
    payment_mode: '',
    shipment_id: '',
    orderId: '', // Added orderCode to initial filters state
  })

  const statusOptions = [
    { value: 'new', label: 'New' },
    { value: 'to_be_picked', label: 'To be Picked' },
    { value: 'pickup_ready', label: 'Pickup Ready' },
    // { value: 'pickup_completed', label: 'Pickup Completed' },
    { value: 'in_transit', label: 'In Transit' },
    // { value: 'out_for_delivery', label: 'Out For Delivery' },
    { value: 'delivered', label: 'Delivered' },
    { value: 'rto', label: 'RTO' },
    // { value: 'undelivered', label: 'Undelivered' },
    // { value: 'rto_initiated', label: 'RTO Initiated' },
    // { value: 'cancelled', label: 'Cancelled' },
  ]

  const payment_modeOptions = [
    { value: 'COD', label: 'Cash on Delivery' },
    { value: 'PREPAID', label: 'Prepaid' },
  ]

  const initialFilters = {
    phone: '',
    dateRange: [today.subtract(1, 'day'), today],
    awb: '',
    status: '',
    payment_mode: '',
    shipment_id: '',
    orderId: '', // Added orderCode to initialFilters
  }

  const filterArray = [
    {
      key: 'dateRange',
      type: 'date',
      onChange: (dates) => {
        setFilters({ ...filters, dateRange: dates })
      },
    },
    {
      key: 'awb',
      type: 'string',
      placeholder: 'AWB Number',
      onChange: (value) => {
        setFilters({ ...filters, awb: value })
      },
    },
    {
      key: 'status',
      type: 'select',
      placeholder: 'Status',
      options: statusOptions,
      onChange: (value) => {
        setFilters({ ...filters, status: value })
      },
    },
    {
      key: 'payment_mode',
      type: 'select',
      placeholder: 'Payment Mode',
      options: payment_modeOptions,
      onChange: (value) => {
        setFilters({ ...filters, payment_mode: value })
      },
    },
    // {
    //   key: 'shipment_id',
    //   type: 'string',
    //   placeholder: 'Shipment ID',
    //   onChange: (value) => {
    //     setFilters({ ...filters, shipment_id: value })
    //   },
    // },

    // Added new filter for orderCode
    {
      key: 'orderId',
      type: 'string',
      placeholder: 'Order Id',
      onChange: (value) => {
        setFilters({ ...filters, orderId: value })
      },
    },
  ]

  useEffect(() => {
    updateBreadcrumbs((prev) => [
      prev[0],
      {
        key: 'kwikship shipments',
        href: '/kwikship/shipments',
        text: 'Kwik Ship Shipments',
      },
    ])
    fetchShipmentData(1, 20)
  }, [filters])

  useEffect(() => {
    fetchShipmentData()
  }, [tableSettings])

  const columns = [
    {
      title: 'Order Id',
      dataIndex: 'ordercode',
      key: 'orderId',
      render: (orderCode: string) => (
        <Link to={`/kwikship/shipments/${encodeURIComponent(orderCode)}`}>{orderCode}</Link>
      ),
    },
    {
      title: 'AWB Number',
      dataIndex: 'shipment_awb',
      key: 'awb',
    },

    // { title: 'Shipment ID', dataIndex: 'shipment_id', key: 'shipment_id' },
    {
      title: 'Status',
      dataIndex: 'shipment_status',
      key: 'status',
      render: (text: string) => capitalizeWords(text.replace(/_/g, ' ')),
    },
    // { title: 'Order Code', dataIndex: 'order_code', key: 'order_code' },
    { title: 'Weight (g)', dataIndex: 'shipment_weight', key: 'shipment_weight' },
    { title: 'Payment Mode', dataIndex: 'shipment_payment_mode', key: 'shipment_payment_mode' },
    {
      title: 'Pickup Location',
      dataIndex: 'shipment_pickup_details',
      key: 'address1',
      render: (pickupDetails) => {
        const { address1, city, state } = pickupDetails || {}
        return `${address1 || ''}${city ? `, ${city}${state ? `, ${state}` : ''}` : ''}`
      },
    },
    {
      title: 'Creation Date',
      dataIndex: 'shipment_creation_datetime',
      key: 'shipment_creation_datetime',
      render: (text: string) => new Date(text).toLocaleString(),
    },
    {
      title: 'Shipper',
      dataIndex: ['shippername'],
      key: 'shippername',
    },
    {
      title: 'Total Amount',
      dataIndex: 'shipment_total_amount',
      key: 'shipment_total_amount',
      render: (text: string) => `₹${text}`,
    },
    ...(orders?.some((i) => i.show_label)
      ? [
          {
            title: '',
            dataIndex: 'operation',
            fixed: 'right',
            render: (_: any, record: any) =>
              record.show_label ? (
                <Button
                  onClick={() => downloadLabel(record.shipment_shipment_id)}
                  style={{ padding: ' 0px 20px' }}
                  type='primary'
                  size='small'
                >
                  Download Label
                </Button>
              ) : (
                ''
              ),
          },
        ]
      : [{}]),
  ]

  const fetchDataInChunks = async (configs) => {
    const { start_date, end_date, merchant_id, totalCount } = configs
    const CHUNK_SIZE = 200 // max limit pr req
    const chunks = Math.ceil(totalCount / CHUNK_SIZE)
    let allShipments = []

    for (let i = 0; i < chunks; i++) {
      try {
        const response = await getDashboardTableData({
          start_date,
          end_date,
          merchant_id,
          page: i + 1,
          limit: CHUNK_SIZE,
          ...configs.filters,
        })

        if (response.data.shipments) {
          allShipments = [...allShipments, ...response.data.shipments]
        }
      } catch (error) {
        console.error(`Error fetching chunk ${i + 1}:`, error)
      }
    }

    return allShipments
  }

  const fetchShipmentData = async (page = 0, limit = 0, isDownload = false) => {
    let body = {}
    Object.keys(filters).forEach((filter) => {
      if (filters[filter] && filter !== 'dateRange') {
        if (filter === 'orderId') {
          body[filter] = encodeURIComponent(filters[filter]) // encode the orderCode
        } else {
          body[filter] = filters[filter]
        }
      }
    })

    try {
      // for normal table pagination
      if (!isDownload) {
        const response = await getDashboardTableData({
          start_date: filterDateFormatter(filters.dateRange[0]),
          end_date: filterDateFormatter(filters.dateRange[1]),
          merchant_id: merchantConfigs.m_id,
          page: page || tableSettings.page,
          limit: limit || tableSettings.limit,
          ...body,
        })

        if (page && limit) {
          setTableSettings({ ...tableSettings, page: 1 })
        }

        if (response.data.shipments) {
          setOrders([])
          setOrders(response.data.shipments)
        } else {
          setOrders([])
        }

        if (response.data.total_count) {
          setTotalCount(response.data.total_count)
        } else {
          setTotalCount(0)
        }

        return response.data.shipments
      }

      // for download all data inexcel
      else {
        const initialResponse = await getDashboardTableData({
          start_date: filterDateFormatter(filters.dateRange[0]),
          end_date: filterDateFormatter(filters.dateRange[1]),
          merchant_id: merchantConfigs.m_id,
          page: 1,
          limit: 1,
          ...body,
        })

        if (!initialResponse.data.total_count) {
          return []
        }

        const allShipments = await fetchDataInChunks({
          start_date: filterDateFormatter(filters.dateRange[0]),
          end_date: filterDateFormatter(filters.dateRange[1]),
          merchant_id: merchantConfigs.m_id,
          totalCount: initialResponse.data.total_count,
          filters: body,
        })
        return allShipments
      }
    } catch (error) {
      console.error('Error fetching shipment data:', error)
      return []
    }
  }

  // Function to fetch all data for download
  const fetchAllDataForDownload = async () => {
    if (dayjs(filters.dateRange[1]).diff(dayjs(filters.dateRange[0])) / (24 * 60 * 60 * 1000) >= 8) {
      setPopup(true)
      return null
    }
    return await fetchShipmentData(0, 0, true)
  }

  const resetFilters = () => {
    setFilters(initialFilters)
  }

  const handlePaginationChange = (newPage) => {
    setTableSettings({ ...tableSettings, page: newPage })
  }

  function capitalizeWords(str) {
    return str
      .toLowerCase() // Convert the entire string to lowercase first
      .split(' ') // Split the string by spaces into an array of words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
      .join(' ') // Join the words back into a single string
  }

  const downloadLabel = async (specificShipment = null) => {
    let body: any = {}
    body.shipment_id = specificShipment
      ? [specificShipment]
      : orders.filter((i) => selectedOrders.includes(i.ordercode) && i.show_label).map((i) => i.shipment_shipment_id)
    body.merchant_id = merchantConfigs.m_id
    const response: any = await downloadShipmentLabel(body)

    if (response.status === 200 && response.data?.url) {
      // window.open(response.data.url, '_blank')

      const link = document.createElement('a')
      link.href = response.data.url // Set the file URL
      link.download = '' // Optionally, set a default file name
      document.body.appendChild(link)
      link.click() // Trigger the download
      document.body.removeChild(link)

      message.success('Generating label(s), please wait...')
    } else {
      message.error('Oops! Something went wrong')
    }
  }

  return (
    <main style={{ width: '100%' }}>
      <Modal
        closable={true}
        open={popup}
        cancelButtonProps={{ hidden: true }}
        onCancel={() => setPopup(false)}
        onOk={() => setPopup(false)}
      >
        Download is only allowed for maximum 7 days, please contact CSM for more info.
      </Modal>
      <div className='bg bg-white p-3 rounded-md shadow-md mb-4'>
        <div className='mb-4 flex justify-between flex-wrap gap-4'>
          {filterArray.map((filter, index) => {
            switch (filter.type) {
              case 'date':
                return (
                  <RangePicker key={index} value={filters[filter.key]} onChange={filter.onChange} className='w-64' />
                )
              case 'select':
                return (
                  <Select
                    key={index}
                    placeholder={filter.placeholder}
                    options={filter.options}
                    onChange={(value) => filter.onChange(value)}
                    value={filters[filter.key] || undefined} // Use the dynamic key here
                    allowClear
                    className='w-48'
                  />
                )
              default:
                return (
                  <Input
                    key={index}
                    placeholder={filter.placeholder}
                    onChange={(e) => filter.onChange(e.target.value)}
                    value={filters[filter.key]} // Dynamically handle the correct input field
                    className='w-48'
                  />
                )
            }
          })}
        </div>
        <div className='flex justify-end items-center'>
          <div className='flex gap-3'>
            <ShipmentsDownload ordersToCsv={fetchAllDataForDownload} />
            <Button onClick={() => fetchShipmentData()} style={{ backgroundColor: '#004B9D', color: 'white' }}>
              <SearchOutlined />
              Search
            </Button>
            <Button onClick={resetFilters} style={{ border: '2px solid #004B9D', color: '#004B9D' }}>
              Reset Filters
            </Button>
          </div>
        </div>
      </div>
      <div className='bg-white '>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
          {orders.some((i) => i.show_label) ? (
            <Button
              onClick={() => downloadLabel()}
              type='primary'
              size='small'
              disabled={!selectedOrders.length}
              style={{
                position: 'absolute',
                top: '7px',
                right: '10px',
                zIndex: '2',
              }}
            >
              Download Selected Labels
            </Button>
          ) : (
            ''
          )}
        </div>
        <Table
          // @ts-ignore

          columns={columns}
          dataSource={orders}
          rowSelection={{
            onChange: (selectedRowKeys: React.Key[], selectedRows) => {
              setSelectedOrders(selectedRowKeys)
            },
            getCheckboxProps: (record) => {
              return {
                disabled: record.show_label !== 1,
                name: record.ordercode,
              }
            },
            selectedRowKeys: selectedOrders,
            type: 'checkbox',
          }}
          rowKey='ordercode'
          pagination={{
            current: tableSettings.page,
            pageSize: tableSettings.limit,
            total: totalCount,
            showSizeChanger: false,
            onChange: handlePaginationChange,
            position: ['topLeft', 'bottomLeft'],
            showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
          }}
          scroll={{ x: 'max-content' }}
        />
      </div>
    </main>
  )
}

export default Shipments

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stats-container {
  display: flex;
  gap: 24px;
  padding: 20px;
  flex-wrap: wrap;
  width: 100%;
}

.stats-card {
  background: white;
  border-radius: 12px;
  padding: 24px;
  min-width: 280px;
  height: 126px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  flex: 1;
  transition: transform 0.2s ease-in-out;
}

.stats-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
}

.stats-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stats-info {
  flex-grow: 1;
}

.stats-value {
  font-size: 24px;
  font-weight: 700;
  color: #1a237e;
  margin: 0;
  line-height: 1.2;
}

.stats-label {
  color: #64748b;
  font-size: 16px;
  margin: 4px 0 0 0;
}

.icon-wrapper {
  background: #fff8e1;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
}

.stats-icon {
  font-size: 24px;
  color: #ffa000;
}

@media (max-width: 768px) {
  .stats-container {
    flex-direction: column;
  }

  .stats-card {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/ActionCenter/styles/NeedAction.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,aAAa;EACb,eAAe;EACf,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,aAAa;EACb,gBAAgB;EAChB,aAAa;EACb,yCAAyC;EACzC,OAAO;EACP,sCAAsC;AACxC;;AAEA;EACE,2BAA2B;EAC3B,0CAA0C;AAC5C;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE;IACE,sBAAsB;EACxB;;EAEA;IACE,WAAW;EACb;AACF","sourcesContent":[".stats-container {\n  display: flex;\n  gap: 24px;\n  padding: 20px;\n  flex-wrap: wrap;\n  width: 100%;\n}\n\n.stats-card {\n  background: white;\n  border-radius: 12px;\n  padding: 24px;\n  min-width: 280px;\n  height: 126px;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);\n  flex: 1;\n  transition: transform 0.2s ease-in-out;\n}\n\n.stats-card:hover {\n  transform: translateY(-2px);\n  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);\n}\n\n.stats-content {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.stats-info {\n  flex-grow: 1;\n}\n\n.stats-value {\n  font-size: 24px;\n  font-weight: 700;\n  color: #1a237e;\n  margin: 0;\n  line-height: 1.2;\n}\n\n.stats-label {\n  color: #64748b;\n  font-size: 16px;\n  margin: 4px 0 0 0;\n}\n\n.icon-wrapper {\n  background: #fff8e1;\n  border-radius: 50%;\n  width: 48px;\n  height: 48px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-left: 16px;\n}\n\n.stats-icon {\n  font-size: 24px;\n  color: #ffa000;\n}\n\n@media (max-width: 768px) {\n  .stats-container {\n    flex-direction: column;\n  }\n\n  .stats-card {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

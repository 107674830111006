import {
  Card,
  CheckCircleOutlined,
  CloseCircleOutlined,
  Col,
  ExclamationCircleOutlined,
  Row,
  SyncOutlined,
} from 'gokwik-ui-kit'
import { ReactApexChart } from 'gokwik-ui-kit'

import React from 'react'

const ShipmentReasons = ({ rtoReasons, rtoSumarry }) => {
  const NdrReasons = Object.entries(rtoReasons?.ndr_reason || {}).reduce((acc, [key, value]) => {
    const formattedKey = key.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())
    acc[formattedKey] = value
    return acc
  }, {})

  const rtoReasonGraphValues = Object.entries(rtoReasons?.rto_reason || {}).reduce((acc, [key, value]) => {
    const formattedKey = key.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())
    acc[formattedKey] = value
    return acc
  }, {})
  const reasonsData = {
    series: Object.values(NdrReasons) as number[],
    options: {
      chart: {
        type: 'pie' as const,
      },
      labels: Object.keys(NdrReasons),
      title: {
        text: 'NDR Reasons Breakdown',
        align: 'center' as const,
      },
      legend: {
        position: 'bottom' as const,
      },
    },
  }

  const rtoSumarryData = [
    { title: 'Action Done', value: rtoSumarry.action_done, icon: <CheckCircleOutlined />, color: '#52c41a' },
    {
      title: 'Action Required',
      value: rtoSumarry.action_required,
      icon: <ExclamationCircleOutlined />,
      color: '#faad14',
    },
    { title: 'Delivered', value: rtoSumarry.delivered, icon: <CheckCircleOutlined />, color: '#1890ff' },
    { title: 'NDR Raised', value: rtoSumarry.ndr_raised, icon: <SyncOutlined />, color: '#722ed1' },
    { title: 'NDR %', value: `${rtoSumarry.ndr_raised_percentage}%`, icon: <SyncOutlined />, color: '#722ed1' },
    { title: 'RTO', value: rtoSumarry.rto, icon: <CloseCircleOutlined />, color: '#f5222d' },
  ]
  return (
    <main style={{ display: 'flex', gap: '20px', flexWrap: 'wrap', justifyContent: 'center' }}>
      <Card className='mt-6' title='NDR Reasons'>
        {Object.keys(NdrReasons).length > 0 ? (
          <ReactApexChart options={reasonsData.options} series={reasonsData.series} type='pie' width={380} />
        ) : (
          <p>No data available</p>
        )}
      </Card>
      <Card className='mt-6' title='RTO Reasons'>
        {Object.keys(rtoReasonGraphValues).length > 0 ? (
          <ReactApexChart options={reasonsData.options} series={reasonsData.series} type='pie' width={380} />
        ) : (
          <p>No data available</p>
        )}
      </Card>

      <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', padding: '8px', width: '100%' }}>
        <div style={{ display: 'flex', gap: '6px', justifyContent: 'space-between' }}>
          {rtoSumarryData.map((item, index) => (
            <Card
              key={index}
              style={{
                flexBasis: '16%',
                borderLeft: `4px solid ${item.color}`,
                textAlign: 'center',
                padding: '6px',
                flexFlow: 'wrap',
              }}
            >
              <div style={{ fontSize: '12px', fontWeight: 'bold', color: item.color }}>
                {item.icon} {item.title}
              </div>
              <div style={{ fontSize: '14px', fontWeight: 'bold', marginTop: '4px' }}>{item.value}</div>
            </Card>
          ))}
        </div>
      </div>
    </main>
  )
}

export default ShipmentReasons

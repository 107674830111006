import {
  Col,
  DatePicker,
  Row,
  Select,
  SummaryBlocks,
  Button,
  Tooltip,
  Form,
  Tabs,
  AnalyticsCard,
  Card,
  DownloadOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  CaretDownFilled,
  CaretUpFilled,
} from 'gokwik-ui-kit'
import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import {
  getAnalyticsSumarry,
  getShipperSumarry,
  getCarrierLevelShipments,
  getZoneDistribution,
  getRtoReasons,
  getRtoSumarry,
} from '@library/api'
import { useSelector } from 'react-redux'
import { getMerchantDetails } from '@store/user/selectors'
import ShippersSumarry from './components/ShippersSumarry'
import { downloadToCSV } from '@gokwik/utilities'
import { ANALYTICS_CARDS } from '@library/utilities/constants/analytics.constants'
import CarrierLevelShipments from './components/CarrierLevelShipments'
import ZoneDistribution from './components/ZoneDistribution'
import ShipmentReasons from './components/ShipmentReasons'

const ShipmentAnalytics = () => {
  const merchantConfigs = useSelector(getMerchantDetails)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [summaryData, setSummaryData] = useState<any>([])
  const [shipperSumarryData, setShipperSumarryData] = useState<any>([])
  const [carrierLevelShipments, setCarrierLevelShipments] = useState<any>([])
  const [zoneDistribution, setZoneDistribution] = useState<any>([])
  const [ndrRtoReasons, setNdrRtoReasons] = useState<any>([])
  const [ndrRtoSumarry, setNdrRtoSumarry] = useState<any>([])

  const [activeTab, setActiveTab] = useState('forward')
  const [filters, setFilters] = useState({
    current_datetime_range: `${dayjs().subtract(7, 'd').format('YYYY-MM-DD')},${dayjs().format('YYYY-MM-DD')}`,
    compared_datetime_range: `${dayjs().subtract(7, 'd').format('YYYY-MM-DD')},${dayjs().format('YYYY-MM-DD')}`,
    zone: '',
    paymentMode: '',
    merchant_id: merchantConfigs.m_id,
  })

  // Default filters
  const defaultFilters = {
    current_datetime_range: `${dayjs().subtract(7, 'd').format('YYYY-MM-DD')},${dayjs().format('YYYY-MM-DD')}`,
    compared_datetime_range: '2023-09-01,2023-09-30',
    merchant_id: 'default_merchant_id',
    // paymentMode: 'prepaid',
  }

  const items = [
    {
      key: 'forward',
      label: 'Forward',
      children: <ShippersSumarry data={shipperSumarryData || {}} constantHead={'Forward'} />,
    },
    {
      key: 'rto',
      label: 'RTO',
      children: <ShippersSumarry data={shipperSumarryData || {}} constantHead={'RTO'} />,
    },
    {
      key: 'return',
      label: 'Return',
      children: <ShippersSumarry data={shipperSumarryData || {}} constantHead={'Return'} />,
    },
  ]

  const zoneOptions = [
    { value: 'A', label: 'Zone A' },
    { value: 'B', label: 'Zone B' },
    { value: 'C', label: 'Zone C' },
    { value: 'D', label: 'Zone D' },
    { value: 'E', label: 'Zone E' },
  ]
  const paymentModeOptions = [
    { value: 'COD', label: 'Cash on Delivery' },
    { value: 'PREPAID', label: 'Prepaid' },
  ]

  const handleTabChange = (key) => {
    setActiveTab(key)
  }

  const handleDownloadCSV = () => {
    const { overall, shippers } = shipperSumarryData
    const activeTabData = {
      overall: overall[activeTab.toLowerCase()],
      shippers: shippers.map((shipper) => shipper[activeTab.toLowerCase()]),
    }

    const tableHeaders = Object.keys(activeTabData.overall || {})

    const csvData = [
      { shipper: 'Overall', ...activeTabData.overall },

      ...shippers.map((shipper) => ({
        shipper: shipper.shipper_name,
        ...shipper[activeTab.toLowerCase()],
      })),
    ]

    const csvHeaders = ['Shipper', ...tableHeaders.map((header) => header.replace(/_/g, ' ').toUpperCase())]

    const cellKeys = ['shipper', ...tableHeaders]

    downloadToCSV(cellKeys, csvHeaders, csvData, `${activeTab}_summary`)
  }

  const handleApplyFilters = async (defaultFilters) => {
    setLoading(true)
    try {
      const analyticsParams = {
        current_datetime_range: encodeURIComponent(filters.current_datetime_range),
        compared_datetime_range: encodeURIComponent(filters.compared_datetime_range),
        merchant_id: filters.merchant_id,
      }

      const [start_date, end_date] = filters.current_datetime_range.split(',')
      const shipperParams = {
        start_date: encodeURIComponent(start_date),
        end_date: encodeURIComponent(end_date),
        merchant_id: filters.merchant_id,
        payment_mode: filters.paymentMode,
        zone: filters.zone,
      }

      const carrierLevelShipmentParams = {
        merchant_id: filters.merchant_id,
        payment_mode: filters.paymentMode,
        zone: filters.zone,
      }

      const zoneDistributionParams = {
        merchant_id: filters.merchant_id,
        payment_mode: filters.paymentMode,
        zone: filters.zone,
      }
      const [
        shipperResponse,
        analyticsResponse,
        carrierLevelShipmentResponse,
        zoneDistributionResponse,
        NdrRtoReasonsResponse,
        NdrRtoSumarryResponse,
      ] = await Promise.all([
        getShipperSumarry(shipperParams),
        getAnalyticsSumarry(analyticsParams),
        getCarrierLevelShipments(carrierLevelShipmentParams),
        getZoneDistribution(zoneDistributionParams),
        getRtoReasons({ merchant_id: filters.merchant_id }),
        getRtoSumarry({ merchant_id: filters.merchant_id }),
      ])

      if (analyticsResponse.status === 200) {
        const mappedData = ANALYTICS_CARDS.map((card) => {
          const value = analyticsResponse.data[card.key]?.value || 0
          const change = analyticsResponse.data[card.key]?.change || 0

          return {
            title: card.title,
            tooltipText: card.tooltipText,
            heroValue: (
              <span>
                {card.key === 'realizedRevenue' ? value.toString() : `${value}%`}
                {change !== 0 && (
                  <span style={{ marginLeft: '8px' }}>
                    {change > 0 ? (
                      <CaretUpFilled style={{ color: 'green', fontSize: '16px' }} />
                    ) : (
                      <CaretDownFilled style={{ color: 'red', fontSize: '16px' }} />
                    )}
                  </span>
                )}
              </span>
            ),
            valueDelta: change,
          }
        })

        setSummaryData(mappedData)
      } else {
        console.error('Error fetching analytics:', analyticsResponse.message)
      }

      if (shipperResponse.status === 200) {
        setShipperSumarryData(shipperResponse.data)
      } else {
        console.error('Error fetching shipper summary:', shipperResponse.message)
      }

      if (carrierLevelShipmentResponse.status === 200) {
        setCarrierLevelShipments(carrierLevelShipmentResponse.data)
      } else {
        console.error('Error fetching carrierLevelShipmentResponse', carrierLevelShipmentResponse.message)
      }
      if (zoneDistributionResponse.status === 200) {
        setZoneDistribution(zoneDistributionResponse.data)
      } else {
        console.error('Error fetching zoneDistributionResponse', zoneDistributionResponse.message)
      }
      if (NdrRtoReasonsResponse.status === 200) {
        setNdrRtoReasons(NdrRtoReasonsResponse.data)
      } else {
        console.error('Error fetching zoneDistributionResponse', NdrRtoReasonsResponse.message)
      }

      if (NdrRtoSumarryResponse.status === 200) {
        setNdrRtoSumarry(NdrRtoSumarryResponse.data)
      } else {
        console.error('Error fetching zoneDistributionResponse', NdrRtoSumarryResponse.message)
      }
    } catch (error) {
      console.error('API error:', error)
    }
    setLoading(false)
  }

  useEffect(() => {
    handleApplyFilters(defaultFilters)
  }, [])

  return (
    <main className='w-full'>
      <Form form={form} onFinish={handleApplyFilters}>
        <Row align='stretch' className='pb-3 flex justify-between'>
          <Col className='flex gap-6 '>
            <div className='flex flex-col'>
              <span>Current</span>
              <DatePicker.RangePicker
                defaultValue={[
                  dayjs(filters.current_datetime_range.split(',')[0]),
                  dayjs(filters.current_datetime_range.split(',')[1]),
                ]}
                onChange={(dates) =>
                  setFilters((prev) => ({
                    ...prev,
                    current_datetime_range: dates ? dates.map((d) => dayjs(d).format('YYYY-MM-DD')).join(',') : '',
                  }))
                }
              />
            </div>
            <div className='flex flex-col'>
              <span>Compared</span>
              <DatePicker.RangePicker
                defaultValue={[
                  dayjs(filters.compared_datetime_range.split(',')[0]),
                  dayjs(filters.compared_datetime_range.split(',')[1]),
                ]}
                placeholder={['Compare at', 'Date']}
                onChange={(dates) =>
                  setFilters((prev) => ({
                    ...prev,
                    compared_datetime_range: dates ? dates.map((d) => dayjs(d).format('YYYY-MM-DD')).join(',') : '',
                  }))
                }
              />
            </div>
            <div className='flex flex-col'>
              <span>Zone</span>
              <Select
                mode='multiple'
                allowClear
                placeholder='Select Zone'
                // defaultValue={zoneOptions[0]?.value ? [zoneOptions[0]?.value] : []}
                options={zoneOptions}
                maxTagCount={1}
                onChange={(value) => setFilters((prev) => ({ ...prev, zone: value }))}
                dropdownRender={(menu) => <div className='max-h-60 overflow-y-auto'>{menu}</div>}
                style={{ width: '200px' }}
              />
            </div>
            <div className='flex flex-col'>
              <span>Payment Mode</span>
              <Select
                mode='multiple'
                allowClear
                maxTagCount={1}
                placeholder='Payment Mode'
                // defaultValue={paymentModeOptions[0].value}
                options={paymentModeOptions}
                onChange={(value) => setFilters((prev) => ({ ...prev, paymentMode: value }))}
                style={{ width: '200px' }}
                dropdownRender={(menu) => <div className='max-h-60 overflow-y-auto'>{menu}</div>}
              />
            </div>
          </Col>
          <Col>
            <Button className='mt-5' type='primary' htmlType='submit' loading={loading}>
              Apply Filters
            </Button>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col className='summary-section w-full'>
          <SummaryBlocks data={summaryData} />
        </Col>
      </Row>

      <div className='mt-6'>
        <Card
          title='Shipper Summary'
          extra={
            <Button type='link' icon={<DownloadOutlined />} onClick={handleDownloadCSV}>
              Download
            </Button>
          }
        >
          <Tabs activeKey={activeTab} onChange={handleTabChange} items={items} />
        </Card>

        <CarrierLevelShipments data={carrierLevelShipments} />

        <ZoneDistribution data={zoneDistribution} />
        <ShipmentReasons rtoReasons={ndrRtoReasons} rtoSumarry={ndrRtoSumarry} />
      </div>
    </main>
  )
}

export default ShipmentAnalytics

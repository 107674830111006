import React, { useEffect, useState } from 'react'
import { updateBreadcrumbs } from '@gokwik/utilities'
import { REPORTS_TYPES } from '@library/utilities/constants/reports.constant'
import {
  Button,
  Col,
  DatePicker,
  Form,
  message,
  Row,
  Select,
  Table,
  Tooltip,
  DownCircleOutlined,
  CloudDownloadOutlined,
} from 'gokwik-ui-kit'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { downloadMerchantReport, fetchMerchantReport, generateMerchantReport } from '@library/api'

const Reports = () => {
  const merchantDetails = useSelector(getMerchantDetails)
  const userDetails = useSelector(getUserDetails)
  const { RangePicker } = DatePicker
  const [form] = Form.useForm()
  const defaultDateRange = [dayjs().startOf('day'), dayjs().endOf('day')]
  const [merchantReports, setMerchantReports] = useState([])
  const [loading, setLoading] = useState(false)

  const reportsTypes = REPORTS_TYPES

  const columns = [
    {
      title: 'Name',
      dataIndex: 'reportName',
      key: 'name',
      width: 200,
      ellipsis: true,
      render: (text: string) => (
        <Tooltip placement='topLeft' title={text}>
          <div className='truncate' style={{ maxWidth: 180 }}>
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: 'Generated On',
      dataIndex: 'generatedOn',
      key: 'generatedOn',
      render: (text: string) => dayjs(text).format('DD/MM/YYYY hh:mm A'),
    },
    { title: 'Generated By', dataIndex: 'generatedBy', key: 'generatedBy' },
    { title: 'Merchant', dataIndex: 'merchantName', key: 'merchant' },
    { title: 'Type', dataIndex: 'reportType', key: 'type' },
    {
      title: 'Date Range',
      dataIndex: 'dateRange',
      key: 'dateRange',
      render: (_, record) => {
        const { from, to } = record.dateRange
        return `${dayjs(from).format('DD/MM/YYYY')} - ${dayjs(to).format('DD/MM/YYYY')}`
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => (
        <Button
          type={record.status === 'pending' ? 'primary' : 'default'}
          size='small'
          className='rounded-full px-3 py-1 text-xs'
        >
          {record.status}
        </Button>
      ),
    },

    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <div className='flex justify-center'>
          {record.status === 'COMPLETE' && (
            <a onClick={() => handleAction(record)}>
              <CloudDownloadOutlined />
            </a>
          )}
        </div>
      ),
    },
  ]

  useEffect(() => {
    updateBreadcrumbs((prev) => [prev[0], { key: 'Reports', href: '/kwikship/reports', text: 'Reports' }])
    fetchMerchantReports()
  }, [])

  useEffect(() => {
    if (merchantReports.length > 0) {
      const interval = setInterval(() => {
        fetchMerchantReports()
      }, 10000)
      return () => clearInterval(interval)
    }
  }, [merchantReports.length])

  // handle action
  const handleAction = async (record) => {
    setLoading(true)
    try {
      const response = await downloadMerchantReport(record.id)
      if (response.data?.data?.s3url) {
        const link = document.createElement('a')
        link.href = response.data?.data?.s3url
        link.download = ''
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }

      setLoading(false)
    } catch (error) {
      console.error('Error downloading report:', error)
      message.error('Failed to download report.')
    }
  }

  const fetchMerchantReports = async () => {
    setLoading(true)
    try {
      const payload = {
        generatedOn: {
          from: dayjs().subtract(3, 'day').startOf('day').valueOf(),
          to: dayjs().endOf('day').valueOf(),
        },
        merchantId: [merchantDetails.id],
        pageNo: 1,
        perPage: 25,
      }

      const response = await fetchMerchantReport(payload)

      if (response.status === 201 && response.data) {
        const reports = (response.data.reports || []).filter(
          (report) =>
            report.reportType === 'kwikShipOrders' ||
            report.reportType === 'stuckShipment' ||
            report.reportType === 'shipment',
        )
        setMerchantReports(reports)
      }
      setLoading(false)
    } catch (error) {
      console.error('Error fetching reports:', error)
      message.error('Failed to fetch reports.')
    }
  }

  const handleSubmit = async (values) => {
    try {
      setLoading(true)
      const { dateRange, reportType } = values

      console.log(values)
      const startDate = dayjs(dateRange[0]).startOf('day').valueOf()
      const endDate = dayjs(dateRange[1]).endOf('day').valueOf()
      const payload = {
        reportType,
        filters: {
          createdOn: { from: startDate, to: endDate },
        },
        generatedOn: { from: startDate, to: endDate },
        merchantId: [merchantDetails.id],
        viewUnmaskedReport: true,
        generatedBy: userDetails.name,
        mailTo: [userDetails.email],
        alphaNumericMid: merchantDetails.m_id,
      }

      if (![startDate, endDate].every((t) => String(t).length === 13)) {
        message.error('Invalid date format')
        setLoading(false)
        return
      }

      const response = await generateMerchantReport(payload)

      if (response.status === 201) {
        message.success(response?.data?.message)
        form.resetFields()
        fetchMerchantReports()
      } else {
        throw new Error('Failed to generate report')
      }
    } catch (error) {
      console.error('Report generation error:', error)
      message.error(error.response?.data?.message || 'Error generating report')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='overflow-auto w-full dashboard-reports'>
      <div className='bg-white p-4'>
        <Form
          form={form}
          initialValues={{ dateRange: defaultDateRange, reportType: 'shipment' }}
          layout='vertical'
          onFinish={handleSubmit}
        >
          <div className='flex flex-wrap justify-between items-center gap-4'>
            <div className='flex flex-wrap gap-4 items-center'>
              <Form.Item name='dateRange' className='mb-0'>
                <RangePicker className='w-[250px]' format='YYYY-MM-DD HH:mm:ss' />
              </Form.Item>
              <Form.Item name='reportType' className='mb-0'>
                <Select style={{ width: '150px' }} placeholder='Report Type'>
                  {reportsTypes.map(({ value, text }) => (
                    <Select.Option key={value} value={value}>
                      {text}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <Form.Item className='mb-0'>
              <Button htmlType='submit' variant='primary' className='flex items-center px-4 py-2'>
                <span className='text-white'>Generate Report</span>
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>

      <div className='mt-5 bg-white rounded overflow-clip'>
        <p className='p-4 pl-2 text-sm'>Reports generated in last 7 days</p>
        <Table
          className='ordersTable'
          columns={columns}
          dataSource={merchantReports}
          loading={loading}
          rowClassName={(record) =>
            (new Date().getTime() - new Date(record.generatedOn).getTime()) / 1000 < 30 &&
            `animate-bg-${record.status.toLowerCase()}`
          }
          scroll={{ x: 'max-content' }}
        />
      </div>
    </div>
  )
}

export default Reports

import { Col, PhoneOutlined, Row, SolutionOutlined, Tooltip, UserAddOutlined } from 'gokwik-ui-kit'
import { AddressTypes } from '../types/types'

const CustomerDetails: React.FC<{ details: AddressTypes }> = ({ details }) => {
  const customerInfo = [
    { icon: <SolutionOutlined />, label: 'Name', value: details.name },
    { icon: <UserAddOutlined />, label: 'Email', value: details.email },
    { icon: <PhoneOutlined />, label: 'Phone', value: details.phone },
  ]

  return (
    <Row gutter={[0, 16]} className='p-2 w-full rounded bg-neutrals-15' align='middle' justify='space-between'>
      <Col span={24}>
        <span className='text-black text-base inter font-semibold m-0'>Customer Details</span>
      </Col>
      <Col className='flex flex-col gap-y-2' span={24}>
        {customerInfo.map((detail, index) => (
          <div key={index} className='flex items-center justify-between w-full text-sm inter font-normal'>
            <div className='text-gray-600 flex items-center'>
              <span className='mr-2'>{detail.icon}</span>
              <span>{detail.label}</span>
            </div>
            <Tooltip placement='topLeft' title={detail.value || 'N/A'}>
              <span className='text-black font-medium max-w-[50%] truncate'>{detail.value || 'N/A'}</span>
            </Tooltip>
          </div>
        ))}
      </Col>
    </Row>
  )
}

export default CustomerDetails

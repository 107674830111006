import { Col, Row } from "gokwik-ui-kit";
import { AddressTypes } from "../types/types";

const AddressCard: React.FC<{ title: string; address: AddressTypes }> = ({ title, address }) => (
  <Row gutter={[0, 16]} className='p-2 w-full rounded bg-neutrals-15' align='middle' justify='space-between'>
    <Col span={24}>
      <span className='text-black text-base inter font-semibold m-0'>{title}</span>
    </Col>
    <Col className='flex flex-col gap-y-2 text-sm text-gray-600' span={24}>
      <span className='text-black font-semibold'>{address.name}</span>
      <span>{address.phone}</span>
      <span>{address.address1}</span>
      {address.address2 && <span>{address.address2}</span>}
      <span>
        {address.city}, {address.state}, {address.pincode}
      </span>
      <span>{address.country}</span>
      {address.email && <span>{address.email}</span>}
    </Col>
  </Row>
)


export default AddressCard
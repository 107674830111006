export const API_ENDPOINTS = {
  DASHBOARD_TABLE: '/dashboard/shipments',
  SHIPMENT_DETAILS: '/dashboard/shipments',
  SHIPMENT_ANALYTICS: '/dashboard/summary',
  ORDERS_TABLE: '/dashboard/orders',
  ORDERS_UPDATE: '/dashboard/order/update',
  BULK_SHIP: '/dashboard/orders/process',
  BULK_UPDATE: '/dashboard/orders/bulkUpdate',
  LOCATIONS: '/dashboard/warehouse/location',
  DOWNLOAD_LABEL: '/dashboard/generate/label',
  SHIPMENT_REATTEMPT: '/dashboard/shipments/reattempt',
  SHIPMENT_CANCEL: '/dashboard/cancel',
  DELAY_AND_NRD_COUNTS: '/dashboard/shipments/analytics',
  DASHBOARD_ANALYTICS_SUMARRY: '/dashboard/analytics',
  SHIPPER_SUMARRY: '/dashboard/shipments/stats',
  CARRIER_LEVEL_SHIPMENTS: '/dashboard/shipments/count',
  ZONE_DISTRIBUTION: '/dashboard/shipments/zone',
  NDR_RTO_REASONS: '/dashboard/shipments/ndr-rto-reason',
  NDR_RTO_SUMARRY: '/dashboard/shipments/ndr-summary',
  CREATE_MANUAL_DISPUTE: '/dashboard/dispute/create',
  RESOLVE_MANUAL_DISPUTE: '/dashboard/dispute/resolve',
}

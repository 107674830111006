import { Button, Col, DownloadOutlined, InboxOutlined, Row, Upload, message } from 'gokwik-ui-kit'
import Test from '@public/assets/order_update.csv'
import { useState } from 'react'

const { Dragger } = Upload
type Props = {
  uploadCSV?: (originFileObj: any, fileList: any) => void
  formState?: any
}

export const FileUpload = (props: Props) => {
  const { uploadCSV, formState } = props
  const [file, setFile] = useState(null)
  const [fileList, setFileList] = useState([])
  const downloadSample = () => {
    var pom = document.createElement('a')
    pom.href = Test
    pom.setAttribute('download', 'order_update_sample.csv')
    pom.click()
    message.success('Sample downloaded successfully!')
  }

  return (
    <div>
      <Dragger
        fileList={fileList}
        accept='.csv'
        name='file'
        maxCount={1}
        multiple={false}
        beforeUpload={(file, fileList) => {
          setFile(file)
          setFileList([file])
          return false
        }}
        onRemove={() => {
          setFile(null)
          setFileList([])
        }}
      >
        <p className='ant-upload-drag-icon'>
          <InboxOutlined />
        </p>
        <p className='ant-upload-text'>Click or drag file to this area to upload</p>
        <p className='text-[#00000073] text-sm'>*Multiple files upload is not allowed</p>
      </Dragger>
      <br />

      {/* <Row>
        <Col span={25}>
          <p className=' font-semibold'>Instructions</p>
          <ul className='text-sm pl-4 mt-2 mb-0'>
            <li>Upload a CSV with a single column of discount codes</li>
            <li>Each code must be between 3 and 30 characters, using only letters and numbers—no special characters</li>
            <li>Ensure no duplicate codes are present in the file</li>
            <li>All codes should be uppercase (ALL CAPS)</li>
          </ul>
        </Col>
      </Row> */}
      <Row align={'middle'} className='p-2'>
        <Button
          disabled={!file}
          style={{ margin: 'auto' }}
          onClick={() => {
            uploadCSV(file, [])
            setFile(null)
            setFileList([])
          }}
          type='primary'
        >
          UPLOAD FILE
        </Button>
      </Row>

      <br />

      <Row align={'middle'} className='border-solid rounded-md border-[#1677FF8C] p-2 bg-[#1677FF0D]'>
        <Col span={16}>
          <span className='text-xs text-gray-600'>
            Please ensure that the uploaded file is in the suggested format as shown in the sample file on the right
          </span>
        </Col>
        <Col span={8}>
          <Button onClick={downloadSample} type='link' variant='default'>
            <DownloadOutlined />
            Download Sample
          </Button>
        </Col>
      </Row>

      <br />
      <Row className='text-sm'>
        <Col span={16}>
          <span>Supported Formats: CSV</span>
        </Col>
        <Col span={8}>
          <span className='text-[#00000073]'>Maximum file size: 6 MB</span>
        </Col>
      </Row>
    </div>
  )
}

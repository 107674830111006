export const ANALYTICS_CARDS = [
  {
    title: 'Delivery Rate',
    tooltipText: 'Percentage of orders successfully delivered.',
    key: 'deliveryRate',
  },
  {
    title: 'RTO Rate',
    tooltipText: 'Percentage of shipments returned to origin.',
    key: 'rtoRate',
  },
  {
    title: 'Realized Revenue',
    tooltipText: 'Revenue realized from delivered shipments.',
    key: 'realizedRevenue',
  },
  {
    title: 'AI Agent Closure Percentage',
    tooltipText: 'Percentage of shipments closed by AI Agent.',
    key: 'aiAgentClosurePercentage',
  },
]

import { ThunkAction, configureStore } from '@reduxjs/toolkit'
import { Action, combineReducers } from 'redux'
import user from './user'

const rootReducer = combineReducers({
  user,
})

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
})

export default store
export type RootStoreType = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootStoreType, unknown, Action<string>>

import React, { useState, useEffect } from 'react'
import {
  Table,
  DatePicker,
  Input,
  Button,
  FilterFilled,
  Select,
  Modal,
  message,
  Form,
  Popover,
  Menu,
  Dropdown,
  MoreOutlined,
  CheckOutlined,
} from 'gokwik-ui-kit'
import { filterDateFormatter, updateBreadcrumbs } from '@gokwik/utilities'
import { cancelShipment, downloadShipmentLabel, getDashboardTableData, shipmentReattempt } from '@library/api'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { getMerchantDetails } from '@store/user/selectors'
import { SearchOutlined } from 'gokwik-ui-kit'
import { RedoOutlined } from 'gokwik-ui-kit'
import { Link, useLocation } from 'react-router-dom'
import ShipmentsDownload from './components/ShipmentsDownload'
import { InitialValues } from './types/types'

const { RangePicker } = DatePicker

const Shipments = () => {
  const today = dayjs()
  const [totalCount, setTotalCount] = useState<number>(0)
  const [orders, setOrders] = useState<any>([])
  const merchantConfigs = useSelector(getMerchantDetails)
  const [popup, setPopup] = useState(false)
  const [selectedOrders, setSelectedOrders] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [editingRecord, setEditingRecord] = useState(null)
  const [form] = Form.useForm()
  const [tableSettings, setTableSettings] = useState<{ page: number; limit: number }>({
    limit: 20,
    page: 1,
  })

  const [filters, setFilters] = useState<any>({
    phone: '',
    awb: '',
    dateRange: [today.subtract(1, 'day'), today],
    status: '',
    payment_mode: '',
    reason: '',
    shipment_id: '',
    orderId: '',
    shipperId: '',
  })

  const url = new URL(window.location.href)
  const searchParams = new URLSearchParams(url.search)
  const status = searchParams.get('status')
  const startDateParam = searchParams.get('start_date')
  const endDateParam = searchParams.get('end_date')

  useEffect(() => {
    if (status === 'undelivered' || status === 'delayed_pickup') {
      const searchParams = new URLSearchParams(location.search)
      const searchParamsObject = Object.fromEntries(searchParams.entries())

      // Convert date parameters to dayjs objects if they exist
      const startDate = searchParamsObject.start_date ? dayjs(searchParamsObject.start_date) : today.subtract(1, 'day')

      const endDate = searchParamsObject.end_date ? dayjs(searchParamsObject.end_date) : today

      // Update filters state with query parameters
      setFilters((prevFilters) => ({
        ...prevFilters,
        dateRange: [startDate, endDate],
        status: searchParamsObject.status || prevFilters.status,
        page: searchParamsObject.page ? parseInt(searchParamsObject.page) : prevFilters.page,
        limit: searchParamsObject.limit ? parseInt(searchParamsObject.limit) : prevFilters.limit,
      }))

      // Update table settings to match pagination from query params
      setTableSettings((prevSettings) => ({
        ...prevSettings,
        page: searchParamsObject.page ? parseInt(searchParamsObject.page) : prevSettings.page,
        limit: searchParamsObject.limit ? parseInt(searchParamsObject.limit) : prevSettings.limit,
      }))
    }
  }, [location.search])
  const statusOptions = [
    { value: 'new', label: 'New' },
    { value: 'to_be_picked', label: 'To be Picked' },
    { value: 'pickup_ready', label: 'Pickup Ready' },
    // { value: 'pickup_completed', label: 'Pickup Completed' },
    { value: 'in_transit', label: 'In Transit' },
    // { value: 'out_for_delivery', label: 'Out For Delivery' },
    { value: 'delivered', label: 'Delivered' },
    { value: 'rto', label: 'RTO' },
    ...(status === 'undelivered'
      ? [{ value: 'undelivered', label: 'Undelivered' }]
      : status === 'delayed_pickup'
      ? [{ value: 'delayed_pickup', label: 'Delayed Pickup' }]
      : []),
    // { value: 'rto_initiated', label: 'RTO Initiated' },
    // { value: 'cancelled', label: 'Cancelled' },
  ]

  const payment_modeOptions = [
    { value: 'COD', label: 'Cash on Delivery' },
    { value: 'PREPAID', label: 'Prepaid' },
  ]

  // adding shipperId options
  const shipperIdOptions = [
    { value: 'Shadowfax', label: 'Shadowfax' },
    { value: 'ShadowfaxNDD', label: 'ShadowfaxNDD' },
    { value: 'ShadowfaxSDD', label: 'ShadowfaxSDD' },
    { value: 'BlitzSDD', label: 'BlitzSDD' },
    { value: 'BlitzNDD', label: 'BlitzNDD' },
    { value: 'PicoNDD', label: 'PicoNDD' },
    { value: 'PicoSDD', label: 'PicoSDD' },
    { value: 'Delhivery', label: 'Delhivery' },
  ]

  // adding reason options
  const reason_Options = [
    { value: 'unable_to_contact_customer', label: 'Unable to contact customer' },
    { value: 'incorrect_address', label: 'Incorrect address' },
    { value: 'new_delivery_date', label: 'New delivery date' },
    { value: 'others', label: 'Others' },
    { value: 'shipment_not_attempted', label: 'Shipment not attempted' },
  ]

  const initialFilters = {
    phone: '',
    dateRange: [today.subtract(1, 'day'), today],
    awb: '',
    status: '',
    reason: '',
    payment_mode: 'COD',
    shipment_id: '',
    orderId: '', // Added orderCode to initialFilters
    shipperId: '',
  }

  const filterArray = [
    // First filter with date range
    {
      key: 'dateRange',
      type: 'date',
      onChange: (dates) => {
        setFilters({ ...filters, dateRange: dates })
      },
    },

    {
      key: 'awb',
      type: 'string',
      placeholder: 'AWB Number',
      onChange: (value) => {
        setFilters({ ...filters, awb: value })
      },
    },
    {
      key: 'status',
      type: 'select',
      placeholder: 'Status',
      options: statusOptions,
      onChange: (value) => {
        setFilters({ ...filters, status: value })
      },
    },
    {
      key: 'payment_mode',
      type: 'select',
      placeholder: 'Payment Mode',
      options: payment_modeOptions,
      onChange: (value) => {
        setFilters({ ...filters, payment_mode: value })
      },
    },
    {
      key: 'shipper_name',
      type: 'select',
      placeholder: 'Select Shipper',
      options: shipperIdOptions,
      onChange: (value) => {
        setFilters({ ...filters, shipper_name: value })
      },
    },
    {
      key: 'orderId',
      type: 'string',
      placeholder: 'Order Id',
      onChange: (value) => {
        setFilters({ ...filters, orderId: value })
      },
    },
  ].concat(
    // Conditionally add the reason filter
    status === 'undelivered'
      ? [
          {
            key: 'reason',
            type: 'select',
            placeholder: 'NDR Reasons',
            options: reason_Options,
            onChange: (value) => {
              setFilters({ ...filters, reason: value })
            },
          },
        ]
      : [],
  )

  useEffect(() => {
    updateBreadcrumbs((prev) => [
      prev[0],
      {
        key: 'kwikship shipments',
        href: '/kwikship/shipments',
        text: 'Kwik Ship Shipments',
      },
    ])
    fetchShipmentData(1, 20)
  }, [filters])

  useEffect(() => {
    fetchShipmentData()
  }, [tableSettings])

  const handleReattemptSubmit = (record) => {
    // Constructing the payload
    const payload = {
      tracking_number: record.shipment_awb || 'DefaultAWB', // Fallback in case data is missing
      shipper_id: record.shipment_shipper_id || '', // Assuming a fixed shipper_id, change if dynamic
      action: 're-attempt', // This seems constant
      deferred_date: dayjs().add(1, 'day').format('YYYY-MM-DD'), // Calculate T+1 date
      phone: record.customer_phone || '0000000000', // Fallback for missing phone
      address: {
        address1: record.shipping_address || 'Default Address 1',
        address2: record.shipment_pickup_details?.address2 || 'Default Address 2',
        pincode: record.shipping_postalcode || '000000',
        city: record.shipping_country || 'Default City',
        state: record.shipping_state || 'Default State',
      },
      action_by: 2,
    }

    shipmentReattempt(payload).then((response) => {
      if (response.status === 200) {
        message.success(response.message)
      } else {
        message.error(response.message)
      }
    })
  }

  const handleReattemptCancel = (record) => {
    try {
      // Constructing the payload
      const payload = {
        tracking_number: record.shipment_awb || 'DefaultAWB', // Fallback in case data is missing
        shipper_id: record.shipment_shipper_id || '', // Assuming a fixed shipper_id, change if dynamic
        action: 'return', // This seems constant
        deferred_date: '', // Replace with a dynamic date if necessary
        phone: record.customer_phone || '0000000000', // Fallback for missing phone
        address: {
          address1: record.shipping_address || 'Default Address 1',
          address2: record.shipment_pickup_details?.address2 || 'Default Address 2',
          pincode: record.shipping_postalcode || '000000',
          city: record.shipping_country || 'Default City',
          state: record.shipping_state || 'Default State',
        },
        action_by: 3,
      }
      shipmentReattempt(payload).then((response) => {
        if (response.status === 200) {
          message.success(response.message)
        } else {
          message.error(response.message)
        }
      })
    } catch (error) {
      message.error('Something went wrong')
    }
  }

  const handleEditShipment = (updatedRecord) => {
    // console.log(updatedRecord, 'here we are the edition record ')
    // Constructing the payload
    const payload = {
      tracking_number: updatedRecord.shipment_awb || 'DefaultAWB', // Fallback in case data is missing
      shipper_id: updatedRecord.shipment_shipper_id || '', // Assuming a fixed shipper_id, change if dynamic
      action: 're-attempt', // This seems constant
      deferred_date: updatedRecord.date ? dayjs(updatedRecord.date).format('YYYY-MM-DD') : '', // Replace with a dynamic date if necessary
      phone: updatedRecord.customer_phone || '0000000000', // Fallback for missing phone
      address: {
        address1: updatedRecord.address || 'Default Address 1',
        address2: updatedRecord.shipment_pickup_details?.address2 || 'Default Address 2',
        pincode: updatedRecord.shipping_postalcode || '000000',
        city: updatedRecord.shipping_country || 'Default City',
        state: updatedRecord.shipping_state || 'Default State',
      },
      action_by: 1,
    }
    // console.log(payload)
    shipmentReattempt(payload).then((response) => {
      if (response.status === 200) {
        message.success(response.message)
      } else {
        message.error(response.message)
      }
    })
  }

  const handleEditModal = () => {
    form
      .validateFields()
      .then((values) => {
        // Parse updates_allowed string to array
        const updatesAllowed = editingRecord.updates_allowed ? JSON.parse(editingRecord.updates_allowed) : []

        // Combine only the allowed updates
        // Combine the edited values with the original record
        const updatedRecord = {
          ...editingRecord,
          ...values,
        }

        // Call the edit shipment function
        handleEditShipment(updatedRecord)

        // Close the modal
        setIsModalVisible(false)

        // Show success message
        message.success('Shipment details updated successfully')
      })
      .catch((errorInfo) => {
        console.log('Validation Failed:', errorInfo)
        message.error('Please fill in all required fields')
      })
  }

  const handleCancelModal = () => {
    setIsModalVisible(false)
  }

  const handleCancelShipment = (record) => {
    // construction payload
    const payload = {
      waybill: record.shipment_awb || 'DefaultAWB',
    }
    cancelShipment(payload)
  }
  const getActionMenu = (record) => {
    const updatesAllowed = record.updates_allowed ? JSON.parse(record.updates_allowed) : []

    return (
      <Menu>
        {record.shipment_status === 'undelivered' && (
          <Menu.Item
            key='reattempt'
            onClick={() => handleReattemptSubmit(record)}
            disabled={record.action_taken}
            icon={record.action_by === 2 ? <CheckOutlined style={{ fontSize: '12px', color: '#52c41a' }} /> : null}
          >
            Reattempt
          </Menu.Item>
        )}

        {record.shipment_status === 'undelivered' && updatesAllowed.length > 0 && (
          <Menu.Item
            key='edit'
            disabled={record.action_taken}
            icon={record.action_by === 1 ? <CheckOutlined style={{ fontSize: '12px', color: '#52c41a' }} /> : null}
            onClick={() => {
              setEditingRecord(record)
              setIsModalVisible(true)

              const initialValues: InitialValues = {
                date: '',
                address: '',
                phoneNo: '',
              }
              if (updatesAllowed.includes('date')) {
                initialValues.date = dayjs().add(1, 'day')
              }
              if (updatesAllowed.includes('address')) {
                initialValues.address = record.shipment_pickup_details?.address1 || ''
              }
              if (updatesAllowed.includes('phone')) {
                initialValues.phoneNo = record.shipment_pickup_details?.phone || ''
              }

              form.setFieldsValue(initialValues)
            }}
          >
            Edit
          </Menu.Item>
        )}

        {record.shipment_status === 'undelivered' && (
          <Menu.Item
            key='rto'
            disabled={record.action_taken}
            icon={record.action_by === 3 ? <CheckOutlined style={{ fontSize: '12px', color: '#52c41a' }} /> : null}
            onClick={() => handleReattemptCancel(record)}
          >
            Mark as RTO
          </Menu.Item>
        )}

        <Menu.Item key='cancel' onClick={() => handleCancelShipment(record)}>
          Cancel
        </Menu.Item>
      </Menu>
    )
  }

  const columns = [
    {
      title: 'Order Id',
      dataIndex: 'ordercode',
      key: 'orderId',
      render: (orderCode: string) => (
        <Link to={`/kwikship/shipments/${encodeURIComponent(orderCode)}`}>{orderCode}</Link>
      ),
    },
    {
      title: 'AWB Number',
      dataIndex: 'shipment_awb',
      key: 'awb',
    },
    {
      title: 'Status',
      dataIndex: 'shipment_status',
      key: 'status',
      render: (text: string) => capitalizeWords(text.replace(/_/g, ' ')),
    },
    status === 'undelivered' && {
      title: 'Last Status',
      dataIndex: 'last_status_update',
      key: 'last_status_update',
      render: (text: string) => new Date(text).toLocaleString(),
    },
    { title: 'Weight (g)', dataIndex: 'shipment_weight', key: 'shipment_weight' },
    { title: 'Payment Mode', dataIndex: 'shipment_payment_mode', key: 'shipment_payment_mode' },
    status === 'undelivered' && {
      title: 'Delivery Address',
      dataIndex: 'shipment_pickup_details',
      key: 'address1',
      render: (pickupDetails) => {
        const { address1, city, state } = pickupDetails || {}
        return `${address1 || ''}${city ? `, ${city}${state ? `, ${state}` : ''}` : ''}`
      },
    },
    {
      title: 'Creation Date',
      dataIndex: 'shipment_creation_datetime',
      key: 'shipment_creation_datetime',
      render: (text: string) => new Date(text).toLocaleString(),
    },
    {
      title: 'Shipper',
      dataIndex: ['shippername'],
      key: 'shippername',
    },
    {
      title: 'Total Amount',
      dataIndex: 'shipment_total_amount',
      key: 'shipment_total_amount',
      render: (text: string) => `₹${text}`,
    },
    {
      title: 'Pickup Location',
      dataIndex: 'shipment_pickup_details',
      key: 'address1',
      render: (pickupDetails) => {
        const { address1, city, state } = pickupDetails || {}
        return `${address1 || ''}${city ? `, ${city}${state ? `, ${state}` : ''}` : ''}`
      },
    },
    ...(status === 'undelivered'
      ? [
          {
            title: 'Contact Number',
            dataIndex: 'shipment_pickup_details',
            key: 'phone',
            render: (pickupDetails) => pickupDetails?.customer_phone || '',
          },
          {
            title: 'Last Status',
            dataIndex: 'last_status_update',
            key: 'last_status_update',
            render: (text: string) => new Date(text).toLocaleString(),
          },
          {
            title: 'NDR Reasons',
            dataIndex: 'shipper_remark',
            key: 'shipper_remark',
            render: (text: string) => text || '',
          },
          {
            title: 'NDR Attempt',
            dataIndex: 'ndr_attempt',
            key: 'ndr_attempt',
            render: (text: string) => text || '',
          },
        ]
      : []),
    ...(orders?.some((i) => i.show_label)
      ? [
          {
            title: '',
            dataIndex: 'operation',
            fixed: 'right',
            render: (_: any, record: any) =>
              record.show_label ? (
                <Button
                  onClick={() => downloadLabel(record.shipment_shipment_id)}
                  style={{ padding: ' 0px 20px' }}
                  type='primary'
                  size='small'
                >
                  Download Label
                </Button>
              ) : (
                ''
              ),
          },
        ]
      : []),
    {
      title: '',
      dataIndex: 'operation',
      fixed: 'right',
      render: (_: any, record: any) => (
        <Dropdown overlay={getActionMenu(record)} trigger={['click']}>
          <MoreOutlined />
        </Dropdown>
      ),
    },
  ].filter(Boolean) // <-- This filters out `false`, `undefined`, or empty objects

  const fetchDataInChunks = async (configs) => {
    const { start_date, end_date, merchant_id, totalCount } = configs
    const CHUNK_SIZE = 200 // max limit pr req
    const chunks = Math.ceil(totalCount / CHUNK_SIZE)
    let allShipments = []

    for (let i = 0; i < chunks; i++) {
      try {
        const response = await getDashboardTableData({
          start_date,
          end_date,
          merchant_id,
          page: i + 1,
          limit: CHUNK_SIZE,
          ...configs.filters,
        })

        if (response.data.shipments) {
          allShipments = [...allShipments, ...response.data.shipments]
        }
      } catch (error) {
        console.error(`Error fetching chunk ${i + 1}:`, error)
      }
    }

    return allShipments
  }

  const fetchShipmentData = async (page = 0, limit = 0, isDownload = false) => {
    let body = {}
    Object.keys(filters).forEach((filter) => {
      if (filters[filter] && filter !== 'dateRange') {
        if (filter === 'orderId') {
          body[filter] = encodeURIComponent(filters[filter]) // encode the orderCode
        } else {
          body[filter] = filters[filter]
        }
      }
    })

    try {
      // for normal table pagination
      if (!isDownload) {
        const response = await getDashboardTableData({
          start_date: filterDateFormatter(filters.dateRange[0]),
          end_date: filterDateFormatter(filters.dateRange[1]),
          merchant_id: merchantConfigs.m_id,
          page: page || tableSettings.page,
          limit: limit || tableSettings.limit,
          ...body,
        })

        if (page && limit) {
          setTableSettings({ ...tableSettings, page: 1 })
        }

        if (response.data.shipments) {
          setOrders([])
          setOrders(response.data.shipments)
        } else {
          setOrders([])
        }

        if (response.data.total_count) {
          setTotalCount(response.data.total_count)
        } else {
          setTotalCount(0)
        }

        return response.data.shipments
      }

      // for download all data inexcel
      else {
        const initialResponse = await getDashboardTableData({
          start_date: filterDateFormatter(filters.dateRange[0]),
          end_date: filterDateFormatter(filters.dateRange[1]),
          merchant_id: merchantConfigs.m_id,
          page: 1,
          limit: 1,
          ...body,
        })

        if (!initialResponse.data.total_count) {
          return []
        }

        const allShipments = await fetchDataInChunks({
          start_date: filterDateFormatter(filters.dateRange[0]),
          end_date: filterDateFormatter(filters.dateRange[1]),
          merchant_id: merchantConfigs.m_id,
          totalCount: initialResponse.data.total_count,
          filters: body,
        })
        return allShipments
      }
    } catch (error) {
      console.error('Error fetching shipment data:', error)
      return []
    }
  }

  // Function to fetch all data for download
  const fetchAllDataForDownload = async () => {
    if (dayjs(filters.dateRange[1]).diff(dayjs(filters.dateRange[0])) / (24 * 60 * 60 * 1000) >= 8) {
      setPopup(true)
      return null
    }
    return await fetchShipmentData(0, 0, true)
  }

  const resetFilters = () => {
    setFilters(initialFilters)
  }

  const handlePaginationChange = (newPage) => {
    setTableSettings({ ...tableSettings, page: newPage })
  }

  function capitalizeWords(str) {
    return str
      .toLowerCase() // Convert the entire string to lowercase first
      .split(' ') // Split the string by spaces into an array of words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
      .join(' ') // Join the words back into a single string
  }

  const downloadLabel = async (specificShipment = null) => {
    let body: any = {}
    body.shipment_id = specificShipment
      ? [specificShipment]
      : orders.filter((i) => selectedOrders.includes(i.ordercode) && i.show_label).map((i) => i.shipment_shipment_id)
    body.merchant_id = merchantConfigs.m_id
    const response: any = await downloadShipmentLabel(body)

    if (response.status === 200 && response.data?.url) {
      // window.open(response.data.url, '_blank')

      const link = document.createElement('a')
      link.href = response.data.url // Set the file URL
      link.download = '' // Optionally, set a default file name
      document.body.appendChild(link)
      link.click() // Trigger the download
      document.body.removeChild(link)

      message.success('Generating label(s), please wait...')
    } else {
      message.error('Oops! Something went wrong')
    }
  }

  return (
    <main style={{ width: '100%' }}>
      <Modal
        closable={true}
        open={popup}
        cancelButtonProps={{ hidden: true }}
        onCancel={() => setPopup(false)}
        onOk={() => setPopup(false)}
      >
        Download is only allowed for maximum 7 days, please contact CSM for more info.
      </Modal>

      {/* Edit Modal */}
      <Modal title='Edit Shipment Details' visible={isModalVisible} onOk={handleEditModal} onCancel={handleCancelModal}>
        <Form form={form} layout='vertical' name='edit_shipment_form'>
          {editingRecord?.updates_allowed && JSON.parse(editingRecord.updates_allowed).includes('date') && (
            <Form.Item
              name='date'
              label='Date'
              rules={[
                {
                  required: true,
                  message: 'Please select a date!',
                },
              ]}
            >
              <DatePicker
                style={{ width: '100%' }}
                defaultValue={dayjs()}
                disabledDate={(current) => {
                  const today = dayjs().startOf('day')
                  return current && (current < today || current > today.add(3, 'day'))
                }}
                format='YYYY-MM-DD'
              />
            </Form.Item>
          )}

          {editingRecord?.updates_allowed && JSON.parse(editingRecord.updates_allowed).includes('address') && (
            <Form.Item
              name='address'
              label='Address'
              rules={[{ required: true, message: 'Please input the address!' }]}
            >
              <Input />
            </Form.Item>
          )}

          {editingRecord?.updates_allowed && JSON.parse(editingRecord.updates_allowed).includes('phone') && (
            <Form.Item
              name='phoneNo'
              label='Phone Number'
              rules={[
                { required: true, message: 'Please input the phone number!' },
                {
                  pattern: /^[0-9]{10}$/,
                  message: 'Please enter a valid 10-digit phone number!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}
        </Form>
      </Modal>
      <div className='bg bg-white p-3 rounded-md shadow-md mb-4'>
        <div className='mb-4 flex justify-between gap-4'>
          {filterArray.map((filter, index) => {
            switch (filter.type) {
              case 'date':
                return (
                  <RangePicker key={index} value={filters[filter.key]} onChange={filter.onChange} className='w-64' />
                )
              case 'select':
                return (
                  <Select
                    key={index}
                    placeholder={filter.placeholder}
                    options={filter.options}
                    onChange={(value) => filter.onChange(value)}
                    value={filters[filter.key] || undefined} // Use the dynamic key here
                    allowClear
                    className='w-48'
                  />
                )
              default:
                return (
                  <Input
                    key={index}
                    placeholder={filter.placeholder}
                    onChange={(e) => filter.onChange(e.target.value)}
                    value={filters[filter.key]} // Dynamically handle the correct input field
                    className='w-48'
                  />
                )
            }
          })}
        </div>
        <div className='flex justify-end items-center'>
          <div className='flex gap-3'>
            <ShipmentsDownload ordersToCsv={fetchAllDataForDownload} />
            <Button onClick={() => fetchShipmentData()} style={{ backgroundColor: '#004B9D', color: 'white' }}>
              <SearchOutlined />
              Search
            </Button>
            <Button onClick={resetFilters} style={{ border: '2px solid #004B9D', color: '#004B9D' }}>
              Reset Filters
            </Button>
          </div>
        </div>
      </div>
      <div className='bg-white '>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
          {orders.some((i) => i.show_label) ? (
            <Button
              onClick={() => downloadLabel()}
              type='primary'
              size='small'
              disabled={!selectedOrders.length}
              style={{
                position: 'absolute',
                top: '7px',
                right: '10px',
                zIndex: '2',
              }}
            >
              Download Selected Labels
            </Button>
          ) : (
            ''
          )}
        </div>
        <Table
          // @ts-ignore

          columns={columns}
          dataSource={orders}
          rowSelection={{
            onChange: (selectedRowKeys: React.Key[], selectedRows) => {
              setSelectedOrders(selectedRowKeys)
            },
            getCheckboxProps: (record) => {
              return {
                disabled: record.show_label !== 1,
                name: record.ordercode,
              }
            },
            selectedRowKeys: selectedOrders,
            type: 'checkbox',
          }}
          rowKey='ordercode'
          pagination={{
            current: tableSettings.page,
            pageSize: tableSettings.limit,
            total: totalCount,
            showSizeChanger: false,
            onChange: handlePaginationChange,
            position: ['topLeft', 'bottomLeft'],
            showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
          }}
          scroll={{ x: 'max-content' }}
        />
      </div>
    </main>
  )
}

export default Shipments

import { axiosInstance } from './config'

export const getCall = async (url, params): Promise<any> => {
  let queryParamsToAdd = ''
  Object.keys(params).forEach((element) => {
    queryParamsToAdd += `${element}=${params[element]}&`
  })
  url += `?${queryParamsToAdd}`
  const response = await axiosInstance.get(process.env.BASE_URL + url, {
    headers: { token: process.env.KWIKSHIP_TOKEN },
  })
  return response
}

import { Collapse } from 'gokwik-ui-kit'
import { BulkUpdateOrders } from './BulkUpdate'

const BulkOperations = () => {
  let collapseItems = [
    {
      key: 'order-update-bulk',
      label: 'Bulk Order Update',
      children: <BulkUpdateOrders />,
    },
  ]
  return (
    <div style={{ width: '100%' }} className='bg-white wd-100 p-3 overflow-y-auto rounded'>
      <Collapse className='rounded-lg' items={collapseItems} />
    </div>
  )
}

export default BulkOperations

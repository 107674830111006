// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highlighted-row {
  background-color: #e6f7ff;
  font-weight: 700;
  color: #1890ff;
}

.ant-table-tbody > tr.highlighted-row > td {
  border-bottom: 2px solid #1890ff;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Analytics/components/styles/analytics.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".highlighted-row {\n  background-color: #e6f7ff;\n  font-weight: 700;\n  color: #1890ff;\n}\n\n.ant-table-tbody > tr.highlighted-row > td {\n  border-bottom: 2px solid #1890ff;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { loaderEvent, makeAPICall } from '@gokwik/utilities'
import { API_ENDPOINTS } from './endpoints'
import { getCall, postCall } from './service'
import axios from 'axios'

export const getDashboardTableData = async (params): Promise<any> => {
  const response = await getCall(API_ENDPOINTS.DASHBOARD_TABLE, params)
  return response.data
}

export const getShipmentDetails = async (orderId: string, merchantId: string) => {
  const sanitizedOrderId = encodeURIComponent(orderId)
  const params = { merchant_id: merchantId }
  const response = await getCall(`${API_ENDPOINTS.SHIPMENT_DETAILS}/${sanitizedOrderId}`, params)
  return response.data
}

export const getShipmentAnalytics = async (params): Promise<any> => {
  const response = await getCall(API_ENDPOINTS.SHIPMENT_ANALYTICS, params)
  return response.data
}

export const getOrdersTableData = async (params): Promise<any> => {
  const response = await getCall(API_ENDPOINTS.ORDERS_TABLE, params)
  return response.data
}

export const getDelayAndNdrCount = async (params): Promise<any> => {
  const response = await getCall(API_ENDPOINTS.DELAY_AND_NRD_COUNTS, params)
  return response.data
}

export const bulkShipOrders = async (headers, body): Promise<any> => {
  const response = await postCall(API_ENDPOINTS.BULK_SHIP, body, headers)
  return response.data
}

export const editOrder = async (body): Promise<any> => {
  const response = await postCall(API_ENDPOINTS.ORDERS_UPDATE, body)
  return response.data
}

export const shipmentReattempt = async (body): Promise<any> => {
  const response = await postCall(API_ENDPOINTS.SHIPMENT_REATTEMPT, body)
  return response.data
}

export const cancelShipment = async (body): Promise<any> => {
  const response = await postCall(API_ENDPOINTS.SHIPMENT_CANCEL, body)
  return response.data
}

export const bulkOrderUpdate = async (headers, body): Promise<any> => {
  loaderEvent({ count: 1 })
  const response = await fetch(process.env.BASE_URL + API_ENDPOINTS.BULK_UPDATE, {
    body,
    method: 'post',
    headers: { ...headers, token: process.env.KWIKSHIP_TOKEN },
  })
  loaderEvent({ count: -1 })
  return response
}

export const fetchLocations = async (merchant_id): Promise<any> => {
  const response = await getCall(`${API_ENDPOINTS.LOCATIONS}`, { merchant_id })
  return response.data
}

export const downloadShipmentLabel = async (body) => {
  const response = await postCall(API_ENDPOINTS.DOWNLOAD_LABEL, body)
  return response.data
}

export const getAnalyticsSumarry = async (params): Promise<any> => {
  const response = await getCall(API_ENDPOINTS.DASHBOARD_ANALYTICS_SUMARRY, params)
  return response.data
}

export const getShipperSumarry = async (params): Promise<any> => {
  const response = await getCall(API_ENDPOINTS.SHIPPER_SUMARRY, params)
  return response.data
}

export const getCarrierLevelShipments = async (params): Promise<any> => {
  const response = await getCall(API_ENDPOINTS.CARRIER_LEVEL_SHIPMENTS, params)
  return response.data
}
export const getZoneDistribution = async (params): Promise<any> => {
  const response = await getCall(API_ENDPOINTS.ZONE_DISTRIBUTION, params)
  return response.data
}

export const getRtoReasons = async (params): Promise<any> => {
  const response = await getCall(API_ENDPOINTS.NDR_RTO_REASONS, params)
  return response.data
}
export const getRtoSumarry = async (params): Promise<any> => {
  const response = await getCall(API_ENDPOINTS.NDR_RTO_SUMARRY, params)
  return response.data
}
export const createManualDisputeAPI = async (body: any): Promise<any> => {
  loaderEvent({ count: 1 })
  const response = await fetch(process.env.BASE_URL + API_ENDPOINTS.CREATE_MANUAL_DISPUTE, {
    body,
    method: 'post',
    headers: { token: process.env.KWIKSHIP_TOKEN },
  })
  loaderEvent({ count: -1 })
  return response
}

export const resolveManualDisputeAPI = async (body: any): Promise<any> => {
  const response = await postCall(API_ENDPOINTS.RESOLVE_MANUAL_DISPUTE, body)
  return response.data
}

export const downloadMerchantReport = async (params) => {
  const url = process.env.REPORT_URL
  const response = await axios.get(`${url}/merchantReportUrl?id=${params}`, {
    withCredentials: true,
  })
  return response
}

export const fetchMerchantReport = async (body) => {
  const url = process.env.REPORT_URL
  const response = await axios.post(`${url}/merchantReports`, body, {
    withCredentials: true,
    headers: { 'Content-Type': 'application/json' },
  })
  return response
}

export const generateMerchantReport = async (body) => {
  const url = process.env.REPORT_URL
  const response = await axios.post(`${url}/generateReport`, body, {
    withCredentials: true,
    headers: { 'Content-Type': 'application/json' },
  })
  return response
}

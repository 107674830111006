import { filterDateFormatter, updateBreadcrumbs } from '@gokwik/utilities'
import { bulkShipOrders, editOrder, fetchLocations, getOrdersTableData } from '@library/api'
import { getMerchantDetails } from '@store/user/selectors'
import dayjs from 'dayjs'
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  RedoOutlined,
  SearchOutlined,
  Select,
  Table,
} from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import OrdersDownload from './OrdersDownload'
const { RangePicker } = DatePicker

const Orders = () => {
  const today = dayjs()
  const [totalCount, setTotalCount] = useState<number>(0)
  const [editingRecord, setEditingRecord] = useState(null)
  const [orders, setOrders] = useState<any>([])
  const merchantConfigs = useSelector(getMerchantDetails)
  const [popup, setPopup] = useState(false)
  const [selectedOrders, setSelectedOrders] = useState([])
  const [tableSettings, setTableSettings] = useState<{ page: number; limit: number }>({
    limit: 20,
    page: 1,
  })
  const [filters, setFilters] = useState<any>({
    phone: '',
    awb: '',
    dateRange: [today.subtract(2, 'day'), today],
    status: '',
    paymentMode: '',
    shipment_id: '',
    orderId: '', // Added orderCode to initial filters state
  })

  const [locations, setLocations] = useState([])

  const statusOptions = [
    { value: 'new', label: 'New' },
    { value: 'unprocessable', label: 'Unprocessable' },
    { value: 'review', label: 'Review' },
    { value: 'cancelled', label: 'Cancelled' },
  ]

  const paymentModeOptions = [
    { value: 'COD', label: 'Cash on Delivery' },
    { value: 'PREPAID', label: 'Prepaid' },
  ]

  const initialFilters = {
    phone: '',
    dateRange: [today.subtract(1, 'day'), today],
    awb: '',
    status: '',
    paymentMode: '',
    shipment_id: '',
    orderId: '', // Added orderCode to initialFilters
  }

  const filterArray = [
    {
      key: 'dateRange',
      type: 'date',
      onChange: (dates) => {
        setFilters({ ...filters, dateRange: dates })
      },
    },
    {
      key: 'status',
      type: 'select',
      placeholder: 'Status',
      options: statusOptions,
      onChange: (value) => {
        setFilters({ ...filters, status: value })
      },
    },
    {
      key: 'paymentMode',
      type: 'select',
      placeholder: 'Payment Mode',
      options: paymentModeOptions,
      onChange: (value) => {
        setFilters({ ...filters, paymentMode: value })
      },
    },
    // {
    //   key: 'shipment_id',
    //   type: 'string',
    //   placeholder: 'Shipment ID',
    //   onChange: (value) => {
    //     setFilters({ ...filters, shipment_id: value })
    //   },
    // },

    // Added new filter for orderCode
    {
      key: 'orderId',
      type: 'string',
      placeholder: 'Order Id',
      onChange: (value) => {
        setFilters({ ...filters, orderId: value })
      },
    },
  ]

  useEffect(() => {
    updateBreadcrumbs((prev) => [
      prev[0],
      {
        key: 'kwikship shipments',
        href: '/kwikship/shipments',
        text: 'Kwik Ship Shipments',
      },
    ])
    fetchOrdersData(1, 20)
  }, [filters])

  useEffect(() => {
    // fetchOrdersData()
    loadLocations()
  }, [tableSettings])

  const handleEditClick = (record) => {
    setEditingRecord(record)
  }

  const loadLocations = async () => {
    const response = await fetchLocations(merchantConfigs.m_id)
    setLocations(response.data?.location_ids || [])
  }

  const columns = [
    {
      title: 'Order Id',
      dataIndex: 'order_orderId',
      key: 'orderId',
      //   render: (orderCode: string) => (
      //     <Link to={`/kwikship/shipments/${encodeURIComponent(orderCode)}`}>{orderCode}</Link>
      //   ),
    },

    // { title: 'Shipment ID', dataIndex: 'shipment_id', key: 'shipment_id' },
    {
      title: 'Status',
      dataIndex: 'order_shipment_status',
      key: 'status',
      render: (text: string) => capitalizeWords(text.replace(/_/g, ' ')),
    },
    // { title: 'Order Code', dataIndex: 'order_code', key: 'order_code' },
    {
      title: 'Weight (g)',
      dataIndex: 'order_Shipment.weight',
      key: 'shipment_weight',
      editable: true,
      render: (_, record) => {
        if (editingRecord && editingRecord.order_orderId === record.order_orderId) {
          return (
            <InputNumber
              value={editingRecord.order_Shipment.weight}
              onChange={(value) =>
                value &&
                setEditingRecord({
                  ...editingRecord,
                  order_Shipment: { ...editingRecord.order_Shipment, weight: value },
                })
              }
            ></InputNumber>
          )
        } else {
          return record.order_Shipment.weight
        }
      },
    },
    {
      title: 'Payment Mode',
      dataIndex: 'order_paymentMode',
      key: 'shipment_paymentMode',
      render: (_, record) => {
        if (editingRecord && editingRecord.order_orderId === record.order_orderId) {
          return (
            <Select
              placeholder='Payment Mode'
              value={editingRecord.order_paymentMode}
              onChange={(value) => {
                value && setEditingRecord({ ...editingRecord, order_paymentMode: value })
              }}
              options={paymentModeOptions}
            ></Select>
          )
        } else {
          return record.order_paymentMode
        }
      },
    },
    //     { value: 'COD', label: 'Cash on Delivery' },
    {
      title: 'Pickup Location',
      dataIndex: 'order_pickupAddressDetails',
      key: 'address1',
      render: (pickupDetails: any, record: any) => {
        const { address1, city, state } = pickupDetails || {}

        if (editingRecord && editingRecord.order_orderId === record.order_orderId) {
          return (
            <Select
              placeholder='Pickup Location'
              value={editingRecord.order_location_id}
              onChange={(value) => setEditingRecord({ ...editingRecord, order_location_id: value })}
              options={locations.map((location) => ({
                value: location.location_id,
                label: `${location.pickup_details.address1 || ''}${
                  location.pickup_details.city
                    ? `, ${location.pickup_details.city}${
                        location.pickup_details.state ? `, ${location.pickup_details.state}` : ''
                      }`
                    : ''
                }`,
              }))}
            />
          )
        }
        return `${address1 || ''}${city ? `, ${city}${state ? `, ${state}` : ''}` : ''}`
      },
    },
    {
      title: 'Total Amount',
      dataIndex: 'order_totalAmount',
      key: 'shipment_total_amount',
      render: (text: string) => `₹${text}`,
    },
    {
      title: 'Collectable Amount',
      dataIndex: 'order_collectableAmount',
      key: 'shipment_collectable_amount',
      render: (text: string, record: any) => {
        if (editingRecord && editingRecord.order_orderId === record.order_orderId) {
          return (
            <InputNumber
              placeholder='Collectable Amount'
              value={editingRecord.order_collectableAmount}
              onChange={(value) => {
                value && setEditingRecord({ ...editingRecord, order_collectableAmount: value })
              }}
            />
          )
        } else {
          return `₹${text}`
        }
      },
    },
    {
      title: 'Created On',
      dataIndex: 'shipment_awb',
      key: 'created_on',
      render: (_: any, record: any) => {
        if (record.order_Shipment.orderDate) {
          return new Date(record.order_Shipment.orderDate).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })
        } else return ''
      },
    },
    {
      title: '',
      dataIndex: 'operation',
      fixed: 'right',
      render: (_: any, record: any) =>
        editingRecord && editingRecord.order_orderId === record.order_orderId ? (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
            <Button onClick={() => setEditingRecord(null)} style={{ padding: ' 0px 20px' }} type='dashed' size='small'>
              Cancel
            </Button>
            <Button onClick={() => handleEditOrder()} style={{ padding: ' 0px 20px' }} type='primary' size='small'>
              Submit
            </Button>
          </div>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
            <Button
              disabled={
                selectedOrders.length > 0 || !['new', 'review', 'unprocessable'].includes(record.order_shipment_status)
              }
              onClick={() => handleEditClick(record)}
              style={{ padding: ' 0px 20px' }}
              type='dashed'
              size='small'
            >
              Edit
            </Button>
            <Button
              onClick={() => handleShipOrders(record.order_orderId)}
              disabled={
                selectedOrders.length > 0 || !['new', 'review', 'unprocessable'].includes(record.order_shipment_status)
              }
              style={{ padding: ' 0px 20px' }}
              type='primary'
              size='small'
            >
              Ship
            </Button>
          </div>
        ),
    },
  ]

  const fetchDataInChunks = async (configs) => {
    const { start_date, end_date, merchant_id, totalCount } = configs
    const CHUNK_SIZE = 200 // max limit pr req
    const chunks = Math.ceil(totalCount / CHUNK_SIZE)
    let allShipments = []

    for (let i = 0; i < chunks; i++) {
      try {
        const response = await getOrdersTableData({
          start_date,
          end_date,
          merchant_id,
          page: i + 1,
          limit: CHUNK_SIZE,
          ...configs.filters,
        })

        if (response.data.orders) {
          allShipments = [...allShipments, ...response.data.orders]
        }
      } catch (error) {
        console.error(`Error fetching chunk ${i + 1}:`, error)
      }
    }

    return allShipments
  }

  const handleShipOrders = async (specificOrder = null) => {
    if (selectedOrders.length > 0 || specificOrder) {
      let orderIds = specificOrder ? [specificOrder] : selectedOrders
      const response = await bulkShipOrders({ merchant_id: merchantConfigs.m_id }, { orderIds })
      if (response.status === 200) {
        message.success(`Processing order${!specificOrder ? 's' : ''}, please wait`)
      } else {
        message.error('Error in processing orders')
      }
    }
  }

  const handleEditOrder = async () => {
    let orderUpdateBody = {
      merchant_id: merchantConfigs.m_id,
      order_id: editingRecord.order_orderId,
      paymentMode: editingRecord.order_paymentMode,
      weight: editingRecord.order_Shipment.weight,
      location_id: editingRecord.order_location_id,
    }

    const response = await editOrder(orderUpdateBody)
    if (response.status === 200) {
      message.success('Order Updated Successfully')
      fetchOrdersData(1, 20)
    } else {
      message.error('Error in updating order')
    }
    setEditingRecord(null)
  }

  const fetchOrdersData = async (page = 0, limit = 0, isDownload = false) => {
    let body = {}
    Object.keys(filters).forEach((filter) => {
      if (filters[filter] && filter !== 'dateRange') {
        if (filter === 'orderId') {
          body[filter] = encodeURIComponent(filters[filter]) // encode the orderCode
        } else {
          body[filter] = filters[filter]
        }
      }
    })

    try {
      // for normal table pagination
      if (!isDownload) {
        const response = await getOrdersTableData({
          start_date: filterDateFormatter(filters.dateRange[0]),
          end_date: filterDateFormatter(filters.dateRange[1]),
          merchant_id: merchantConfigs.m_id,
          page: page || tableSettings.page,
          limit: limit || tableSettings.limit,
          ...body,
        })

        if (page && limit) {
          setTableSettings({ ...tableSettings, page: 1 })
        }

        if (response.data.orders) {
          setOrders([])
          setOrders(response.data.orders)
        } else {
          setOrders([])
        }

        if (response.data.total_count) {
          setTotalCount(response.data.total_count)
        } else {
          setTotalCount(0)
        }

        return response.data.orders
      }

      // for download all data inexcel
      else {
        const initialResponse = await getOrdersTableData({
          start_date: filterDateFormatter(filters.dateRange[0]),
          end_date: filterDateFormatter(filters.dateRange[1]),
          merchant_id: merchantConfigs.m_id,
          page: 1,
          limit: 1,
          ...body,
        })

        if (!initialResponse.data.total_count) {
          return []
        }

        const allShipments = await fetchDataInChunks({
          start_date: filterDateFormatter(filters.dateRange[0]),
          end_date: filterDateFormatter(filters.dateRange[1]),
          merchant_id: merchantConfigs.m_id,
          totalCount: initialResponse.data.total_count,
          filters: body,
        })
        return allShipments
      }
    } catch (error) {
      console.error('Error fetching shipment data:', error)
      return []
    }
  }

  // Function to fetch all data for download
  const fetchAllDataForDownload = async () => {
    if (dayjs(filters.dateRange[1]).diff(dayjs(filters.dateRange[0])) / (24 * 60 * 60 * 1000) >= 8) {
      setPopup(true)
      return null
    }
    return await fetchOrdersData(0, 0, true)
  }

  const resetFilters = () => {
    setFilters(initialFilters)
  }

  const handlePaginationChange = async (newPage) => {
    const response = await fetchOrdersData(newPage, tableSettings.limit)
    setTableSettings({ ...tableSettings, page: newPage })
    setOrders(response)
  }


  function capitalizeWords(str) {
    return str
      .toLowerCase() // Convert the entire string to lowercase first
      .split(' ') // Split the string by spaces into an array of words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
      .join(' ') // Join the words back into a single string
  }

  return (
    <main style={{ width: '100%' }}>
      <Modal
        closable={true}
        open={popup}
        cancelButtonProps={{ hidden: true }}
        onCancel={() => setPopup(false)}
        onOk={() => setPopup(false)}
      >
        Download is only allowed for maximum 7 days, please contact CSM for more info.
      </Modal>

      <div className='bg bg-white p-3 rounded-md shadow-md mb-4'>
        <div className='mb-4 flex justify-between flex-wrap gap-4'>
          {filterArray.map((filter, index) => {
            switch (filter.type) {
              case 'date':
                return (
                  <RangePicker key={index} value={filters[filter.key]} onChange={filter.onChange} className='w-64' />
                )
              case 'select':
                return (
                  <Select
                    key={index}
                    placeholder={filter.placeholder}
                    options={filter.options}
                    onChange={(value) => filter.onChange(value)}
                    value={filters[filter.key] || undefined} // Use the dynamic key here
                    allowClear
                    className='w-48'
                  />
                )
              default:
                return (
                  <Input
                    key={index}
                    placeholder={filter.placeholder}
                    onChange={(e) => filter.onChange(e.target.value)}
                    value={filters[filter.key]} // Dynamically handle the correct input field
                    className='w-48'
                  />
                )
            }
          })}
        </div>
        <div className='flex justify-end items-center'>
          <div className='flex gap-3'>
            <OrdersDownload ordersToCsv={fetchAllDataForDownload} />
            <Button onClick={() => fetchOrdersData()} style={{ backgroundColor: '#004B9D', color: 'white' }}>
              <SearchOutlined />
              Search
            </Button>
            <Button onClick={resetFilters} style={{ border: '2px solid #004B9D', color: '#004B9D' }}>
              Reset Filters
            </Button>
          </div>
        </div>
      </div>
      <div className='bg-white '>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
          <Button
            style={{
              position: 'absolute',
              top: '7px',
              right: '10px',
              zIndex: '2',
            }}
            disabled={selectedOrders.length <= 0}
            onClick={() => {
              handleShipOrders()
            }}
            type='primary'
            size='small'
          >
            Ship Selected Orders
          </Button>
        </div>
        <Table
          rowSelection={{
            onChange: (selectedRowKeys: React.Key[], selectedRows) => {
              setSelectedOrders(selectedRowKeys)
            },
            getCheckboxProps: (record) => ({
              disabled: record.name === 'Disabled User', // Column configuration not to be checked
              name: record.name,
            }),
            selectedRowKeys: selectedOrders,
            type: 'checkbox',
          }}
          // @ts-ignore
          columns={columns}
          dataSource={orders}
          rowKey='order_orderId'
          pagination={{
            current: tableSettings.page,
            pageSize: tableSettings.limit,
            total: totalCount,
            showSizeChanger: false,
            onChange: handlePaginationChange,
            showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
          }}
          scroll={{ x: 'max-content' }}
        />
      </div>
    </main>
  )
}

export default Orders

const formatDate = (dateString: string): string => {
  try {
    const date = new Date(dateString)
    return date.toLocaleString('en-IN', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    })
  } catch (error) {
    console.error('Date formatting error:', error)
    return dateString
  }
}

const formatStatus = (status: string): string => {
  if (!status) return '-'
  return status
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

const formatCurrency = (amount: number): string => {
  try {
    return `₹${Number(amount).toLocaleString('en-IN')}`
  } catch (error) {
    console.error('Currency formatting error:', error)
    return '₹0'
  }
}

// Convert grams to kg and format to 2 decimal places
const weightInKg = (weightInGrams) => {
  // Check if weight exists and is a valid number
  if (!weightInGrams && weightInGrams !== 0) return '-'

  // Convert to kg (divide by 1000) and format
  const kg = (parseFloat(weightInGrams) / 1000).toFixed(2)

  // Remove trailing zeros and decimal if whole number
  return kg.replace(/\.?0+$/, '') + ' kg'
}

const cleanString = (string) => {
  return string?.replace(/,/g, ' ')?.replace(/\n/g, ' ')
}
export { formatDate, formatStatus, formatCurrency, weightInKg, cleanString }

import { Col, Row } from 'gokwik-ui-kit'
import { useCallback, useEffect, useMemo } from 'react'

const texts = {
  forward: {
    total_orders: 'Total Shipment',
    pickup_pending: 'Pickup Pending',
    pickup_done: 'Pickup Done',
    in_transit: 'In Transit',
    ofd: 'Out For Delivery',
    delivered: 'Delivered',
  },
  reverse: {
    rto_initiated: 'RTO Initiated',
    return_pickup_completed: 'RTO Pickup Completed',
    return_in_transit: 'RTO In Transit',
    return_delivered: 'RTO Delivered',
  },
  return: {
    undelivered: 'Action Required',
    reattempt_required: 'Reattempt Required',
    ndr_attempt_1: 'Reattempt Required 1',
    ndr_attempt_2: 'Reattempt Required 2',
    ndr_attempt_3: 'Reattempt Required 3',
    ndr_undelivered: 'NDR Not Delivered',
  },
}
const maxHeights = 210
const checkoutAnalyticsNumberFormatter = (val) =>
  Intl.NumberFormat('en-IN', {
    notation: 'compact',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })
    .format(val || 0)
    .replace('T', 'K')
const FunnelChart = ({ data, type, maxValue }) => {
  return (
    <Row className='flex h-full inter' style={{ minHeight: maxHeights + 20 }}>
      <Col span={24}>
        {type ? (
          <Row className='h-full' gutter={[0, 16]} align={'bottom'} justify={'space-between'}>
            {Object.keys(data)?.map((columnName, i) => (
              <Col
                key={columnName}
                className={` flex items-end`}
                style={{
                  width: `${100 / Object.keys(data).length - 1}%`,
                }}
              >
                <div className='w-full flex flex-col justify-end bg-primary-25 rounded-t px-4 pt-4'>
                  <span className='text-sm inter font-semibold text-gray-500'>{texts[type]?.[columnName]}</span>

                  <span className='text-xs inter font-medium text-gray-400'>
                    {i === 0 ? 100 : +((+data[columnName] / +maxValue || 0) * 100).toFixed(2)}%
                  </span>

                  <div className='columns flex items-end  mt-3'>
                    <div
                      className={`min-h-[30px] bg-primary-500 text-white bora-3 text-center py-2 w-1/2 rounded-t`}
                      style={{
                        height: `${(maxHeights * +data[columnName]) / maxValue}px`,
                        width: '100%',
                      }}
                    >
                      {checkoutAnalyticsNumberFormatter(+data[columnName])}
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        ) : (
          <div className='h-full flex items-center justify-center'>
            <p className='text-center lowercase first-letter:uppercase'>
              {typeof data === 'string' ? data : 'No Data'}
            </p>
          </div>
        )}
      </Col>
    </Row>
  )
}

export default FunnelChart

import { API_ENDPOINTS } from './endpoints'
import { getCall } from './service'

export const getDashboardTableData = async (params): Promise<any> => {
  const response = await getCall(API_ENDPOINTS.DASHBOARD_TABLE, params)
  return response.data
}

// function for shipment details
export const getShipmentDetails = async (orderId: string) => {
  // Remove any '#' from the orderId
  const sanitizedOrderId = encodeURIComponent(orderId)

  // Make the API call with the sanitized ID
  const response = await getCall(API_ENDPOINTS.SHIPMENT_DETAILS + '/' + sanitizedOrderId, {})
  return response.data
}

export const getShipmentAnalytics = async (params): Promise<any> => {
  const response = await getCall(API_ENDPOINTS.SHIPMENT_ANALYTICS, params)
  return response.data
}

import React from 'react'
import { Card, Table, Button, DownloadOutlined } from 'gokwik-ui-kit'

import { downloadToCSV } from '@gokwik/utilities'

const ZoneDistribution = ({ data }) => {
  if (!Array.isArray(data) || data.length === 0) return null

  const sortedData = [...data].sort((a, b) => a.zone.localeCompare(b.zone))

  const formatHeader = (header) => {
    return header
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/_/g, ' ')
      .replace(/^./, (str) => str.toUpperCase())
  }

  const columns = Object.keys(sortedData[0]).map((key) => ({
    title: formatHeader(key),
    dataIndex: key,
    key,
    width: 150,
    align: 'center' as const,
  }))

  const dataSource = sortedData.map((item, index) => ({
    key: index.toString(),
    ...item,
  }))

  const handleDownloadCSV = () => {
    const csvHeaders = Object.keys(sortedData[0]).map((key) =>
      key.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase()),
    )

    const cellKeys = Object.keys(sortedData[0])

    downloadToCSV(cellKeys, csvHeaders, sortedData, 'Zone_Distribution')
  }

  return (
    <Card
      className='mt-6'
      title='Zone Distribution'
      extra={
        <Button type='link' icon={<DownloadOutlined />} onClick={handleDownloadCSV}>
          Download
        </Button>
      }
    >
      <Table dataSource={dataSource} columns={columns} pagination={false} bordered={false} scroll={{ x: '100vw' }} />
    </Card>
  )
}

export default ZoneDistribution

import React from 'react'
import { Card, Tooltip, Col } from 'gokwik-ui-kit'

const AnalyticsSummaryCard = ({ block, diff_days, loading }) => {
  return (
    <Col span={6}>
      <div style={{ backgroundColor: '#F0F4F6' }} className=' p-1 rounded-md shadow-md '>
        <div className='bg-white rounded-md p-2'>
          <p style={{ color: '#627f99' }} className='text-sm py-1'>
            {block.heading}
          </p>
          <p className='text-2xl font-bold '>{block.value}</p>
        </div>
        <div className='pb-1 pt-2 text-xs flex justify-between items-center'>
          <div className='bg-white rounded-md p-1'>
            <span style={{ color: block.comparison >= 0 ? 'green' : 'red' }}>
              {block.comparison >= 0 ? '↑' : '↓'} {block.comparison}%
            </span>
          </div>
          <p style={{ color: '#627f99' }} className='text-xs pr-2'>
            Compared to last {diff_days > 1 ? diff_days : ''} day
            {diff_days > 1 ? 's' : ''}
          </p>
        </div>
      </div>
    </Col>
  )
}

export default AnalyticsSummaryCard

import { Table } from 'gokwik-ui-kit'
import './styles/analytics.css'

const ShippersSummary = ({ data, constantHead }) => {
  const { overall, shippers } = data || {}

  const safeOverall = overall || { [constantHead.toLowerCase()]: {} }
  const tableHeaders = Object.keys(safeOverall[constantHead.toLowerCase()] || {})

  const columns = [
    {
      title: 'Shipper',
      dataIndex: 'shipper_name',
      key: 'shipper_name',
      fixed: 'left' as const,
      width: 150,
      align: 'center' as const,
    },
    ...tableHeaders.map((header) => {
      const isTatHeader = header.includes('avg_pickup_to_1st_ofd_tat') || header.includes('avg_pickup_to_delivery_tat')
      return {
        title: isTatHeader
          ? `${header.replace(/_/g, ' ').toUpperCase()} (days)`
          : header.replace(/_/g, ' ').toUpperCase(),
        dataIndex: header,
        key: header,
        width: 150,
        align: 'center' as const,
      }
    }),
  ]

  const formatData = () => {
    return [
      {
        shipper_name: 'Overall',
        ...safeOverall[constantHead.toLowerCase()],
        background: true,
      },
      ...(shippers || []).map((shipper) => ({
        shipper_name: shipper.shipper_name,
        ...shipper[constantHead.toLowerCase()],
      })),
    ]
  }

  const rowClassName = (record) => (record.background ? 'highlighted-row' : '')

  return (
    <div className='analytics-tables'>
      <Table
        dataSource={formatData()}
        columns={columns}
        pagination={false}
        rowClassName={rowClassName}
        bordered={false}
        scroll={{ x: '100vw', y: 'calc(100vh - 350px)' }}
      />
    </div>
  )
}

export default ShippersSummary

export const summary_blocks = [
  {
    heading: 'Delivered',
    tooltipText: 'Sum of sales during the period',
    key: 'delivered',
    value: 0,
    comparison: 0,
  },
  {
    heading: 'RTO Orders',
    tooltipText: 'Sum of Shipments during the period',
    key: 'returned',
    value: 0,
    comparison: 0,
  },
  {
    heading: 'Cancelled Shipments',
    tooltipText: 'Average order value during the period',
    key: 'cancelled',
    value: 0,
    comparison: 0,
  },
  {
    heading: 'Average Delivery TAT',
    tooltipText: 'Number of Shipments placed / Total users reaching checkout',
    key: 'average_delivery_time_hours',
    value: 0,
    comparison: 0,
  },
  {
    heading: 'Average Pickup To OFD',
    tooltipText: "Number of users for which the address was prefilled by GoKwik using Gokwik network's data.",
    key: 'average_ofd_hours',
    value: 0,
    comparison: 0,
  },
  {
    heading: 'Shipments Delivered in SDD/NDD',
    tooltipText: 'Total Shipments for which a prepaid payment was used / Total Shipments placed.',
    key: 'same_or_next_day_deliveries',
    value: 0,
    comparison: 0,
  },
  {
    heading: 'Delivered Revenue',
    tooltipText: 'Total Shipments for which a prepaid payment was used / Total Shipments placed.',
    key: 'delivered_revenue',
    value: 0,
    comparison: 0,
  },
  {
    heading: 'Undelivered',
    tooltipText: 'Total Shipments for which a prepaid payment was used / Total Shipments placed.',
    key: 'undelivered',
    value: 0,
    comparison: 0,
  },
]

export const API_ENDPOINTS = {
  DASHBOARD_TABLE: '/dashboard/shipments',
  SHIPMENT_DETAILS: '/dashboard/shipments',
  SHIPMENT_ANALYTICS: '/dashboard/summary',
  ORDERS_TABLE: '/dashboard/orders',
  ORDERS_UPDATE: '/dashboard/order/update',
  BULK_SHIP: '/dashboard/orders/process',
  BULK_UPDATE: '/dashboard/orders/bulkUpdate',
  LOCATIONS: '/dashboard/warehouse/location',
  DOWNLOAD_LABEL: '/dashboard/generate/label',
  SHIPMENT_REATTEMPT: '/dashboard/shipments/reattempt',
  SHIPMENT_CANCEL: '/dashboard/cancel',
  DELAY_AND_NRD_COUNTS: '/dashboard/shipments/analytics',
}

export const ActionCenterStatus = {
  NDR: 'undelivered',
  STUCK_SHIPMENTS: 'stuck_shipments',
  MANUAL_DISPUTES: 'manual_disputes',
  DELAYED_PICKUPS: 'delayed_pickup',
}

export const PageTitles = {
  undelivered: 'NDR Shipments',
  stuck_shipments: 'Stuck Shipments',
  manual_disputes: 'Manual Disputed Shipments',
  DELAYED_PICKUPS: 'Delayed Pickup Shipments',
}

export const DisputeStatus = {
  dispute_raised: 'Dispute Raised',
  dispute_negative: 'Negative Dispute Response',
  dispute_positive: 'Positive Dispute Response',
  dispute_to_be_raised: 'To Be Raised',
  pending_resolution: 'pending Resolution',
  dispute_resolved: 'Dispute Resolved',
}

import { Button, Card, DownloadOutlined, Table, Tabs } from 'gokwik-ui-kit'
import { useState } from 'react'
import './styles/analytics.css'
import { downloadToCSV } from '@gokwik/utilities'

const CarrierLevelShipments = ({ data }) => {
  const { overall, shippers } = data || {}
  const safeOverall = overall || { forward: {}, return: {} }

  const [activeTab, setActiveTab] = useState('forward')

  const createColumns = (shipmentType) => [
    {
      title: 'Shipper Name',
      dataIndex: 'shipper_name',
      key: 'shipper_name',
      fixed: 'left' as const,
      width: 150,
      align: 'center' as const,
    },
    ...Object.keys(safeOverall[shipmentType] || {}).map((key) => ({
      title: key.replace(/_/g, ' ').toUpperCase(),
      dataIndex: key,
      key,
      align: 'center' as const,
    })),
  ]

  const formatData = (shipmentType) => [
    {
      shipper_name: 'Overall',
      ...safeOverall[shipmentType],
      background: true,
    },
    ...(shippers || []).map((shipper) => ({
      shipper_name: shipper.shipper_name,
      ...shipper[shipmentType],
    })),
  ]

  const rowClassName = (record) => (record.background ? 'highlighted-row' : '')

  const handleDownload = () => {
    const shipmentType = activeTab
    const tableHeaders = Object.keys(safeOverall[shipmentType] || {})

    const csvData = [
      { shipper_name: 'Overall', ...safeOverall[shipmentType] },
      ...shippers.map((shipper) => ({
        shipper_name: shipper.shipper_name,
        ...shipper[shipmentType],
      })),
    ]

    const csvHeaders = ['Shipper Name', ...tableHeaders.map((header) => header.replace(/_/g, ' ').toUpperCase())]
    const cellKeys = ['shipper_name', ...tableHeaders]

    downloadToCSV(cellKeys, csvHeaders, csvData, `${shipmentType}_summary`)
  }

  const tabItems = [
    {
      key: 'forward',
      label: 'Forward',
      children: (
        <Table
          dataSource={formatData('forward')}
          columns={createColumns('forward')}
          pagination={false}
          rowClassName={rowClassName}
          bordered={false}
          scroll={{ x: '100vw', y: 'calc(100vh - 350px)' }}
        />
      ),
    },
    {
      key: 'return',
      label: 'Return',
      children: (
        <Table
          dataSource={formatData('return')}
          columns={createColumns('return')}
          pagination={false}
          rowClassName={rowClassName}
          bordered={false}
          scroll={{ x: '100vw', y: 'calc(100vh - 350px)' }}
        />
      ),
    },
  ]

  return (
    <Card
      className='mt-6'
      title='Carrier Level Shipments'
      extra={
        <Button type='link' icon={<DownloadOutlined />} onClick={handleDownload}>
          Download
        </Button>
      }
    >
      <Tabs defaultActiveKey='forward' items={tabItems} onChange={(key) => setActiveTab(key)} />
    </Card>
  )
}

export default CarrierLevelShipments
